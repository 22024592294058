
import {
  TRadioGroup,
} from 'vue-tailwind/dist/components'

export default {
  component: TRadioGroup,
  props: {
    classes: {
      groupWrapper: 'flex flex-col gap-1',
      wrapper: 'flex items-center',
      label: 'ml-2 mb-2 text-gray-700 text-sm select-none',
      input: 'd-radio d-radio-primary d-radio-sm disabled:d-radio-disabled',
      inputWrapper: '',
      inputWrapperChecked: '',
    },
    variants: {
      danger: {
        label: 'ml-2 mb-2 text-red-600 text-sm select-none',
        input: 'd-radio d-radio-error d-radio-sm disabled:d-radio-disabled'
      },
    }
  }
}
