<template>
	<div class="sm:p-0">
		<p class="text-black font-bold mb-2" v-if="headline">{{ headline }}</p>
		<div :class="{ 'sm:divide-y sm:divide-gray-200': divide }">
			<div
				v-for="(d, index) in data"
				:key="index"
				class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0"
				:class="{ 'py-2': !narrow, 'py-1': narrow }"
			>
				<div class="text-xs font-semibold text-gray-500 uppercase tracking-wider">{{ d.label }}</div>

				<template v-if="Array.isArray(d.value)">
					<ol class="sm:mt-0 sm:col-span-2">
						<li
							class="text-sm font-normal text-gray-800"
							:class="{ 'list-disc': d.value.length > 1 }"
							v-for="(v, index) in d.value"
							:key="index"
						>
							{{ v | upperFirst }}
						</li>
					</ol>
				</template>
				<template v-else>
					<div class="text-sm font-normal text-gray-800 sm:mt-0 sm:col-span-2">{{ d.value | upperFirst }}</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		data: {
			type: [Array, Object],
		},
		divide: {
			type: Boolean,
			default: false,
		},
		narrow: {
			type: Boolean,
			default: false,
		},
		headline: {
			type: String,
			default: null,
		},
	},
}
</script>
