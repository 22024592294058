import en from '@vueform/vueform/locales/en'
import tailwind from '@vueform/vueform/themes/tailwind'
import axios from '@/libs/axios'

export default {
	theme: tailwind,
	locales: { en },
	locale: 'en',
	axios,
}
