<template>
	<div v-if="busy" class="flex justify-center mt-8">
		<span :class="getOption('loadingClass')"></span>
	</div>
	<div v-else>
		<slot
			name="default"
			:data="localData"
			:filteredData="filteredData"
			:groupedData="groupedData"
			:addFilter="addFilter"
			:removeFilter="removeFilter"
			:hasFilter="hasFilter"
			:hasData="hasData"
			:hasMatches="hasMatches"
			:isFiltered="isFiltered"
			:isGrouped="isFiltered"
		></slot>

		<div class="flex justify-between items-center gap-4 mb-4" v-if="getOption('header.totals')">
			<div class="flex items-center min-h-6">
				<p class="text-xs">Showing {{ filteredData.length }} of {{ localData.length }}</p>
			</div>
		</div>

		<ol v-if="hasMatches">
			<li v-for="(group, key) in groupedData" :key="key">
				<div :class="getOption('group.class')" v-if="key !== 'undefined'">
					<div class="flex justify-between items-center gap-4">
						<div class="flex items-center gap-5">
							<img :src="getGroupImgSrc(key)" class="w-10" v-if="getGroupImgSrc(key)" />
							<div>
								<div class="flex items-center gap-2">
									<p class="text-lg font-bold">
										<template v-if="getOption('group.upper')">{{ getGroupKey(key) | upperFirst }}</template>
										<template v-else>{{ key }}</template>
									</p>
								</div>
								<p class="text-sm">{{ getGroupLabel(key) }}</p>
							</div>
						</div>
						<div class="d-badge d-badge-accent" v-if="getOption('group.badge')">{{ group.length }}</div>
					</div>
				</div>
				<ul :class="[containerClass, getGroupClass(key)]">
					<li v-for="(d, index) in group" :key="d.unique">
						<slot name="data" :data="d" :index="index"></slot>
					</li>
				</ul>
			</li>
		</ol>

		<div class="h-full" v-else>
			<div class="p-4" v-if="getOption('messages.noResult')">
				<p class="text-sm text-center">{{ getOption('messages.noResult') }}</p>
			</div>
			<slot name="no-result" :hasData="hasData"> </slot>
		</div>
	</div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import axios from '@/libs/axios'

const props = defineProps({
	data: {
		type: [Object, Array],
	},

	fetch: {
		type: Object,
	},

	dataCallback: {
		type: Function,
		default: (d) => {
			return d
		},
	},

	filterCallback: {
		type: Function,
		default: (d) => {
			return d
		},
	},

	sortingCallback: {
		type: Function,
		default: (d) => {
			return d
		},
	},

	/**
	 * Filters list by `property` equals `value`.
	 *
	 * @param {String} property The property to filter by
	 * @param {String|Number|Boolean} value The value to search for
	 * @example
	 *
	 * { property: 'deactivated', value: true }
	 * [ { property: 'deactivated', value: true}, { property: 'new', value: false} ]
	 *
	 */
	filterBy: {
		type: [Array, Object],
	},

	/**
	 * Rejects list by `property` equals `value`.
	 *
	 * @param {String} property The property to reject by
	 * @param {Array<String|Number|Boolean>} values The values to reject
	 * @example
	 *
	 * { property: 'id', values: [1,9,11] }
	 *
	 */
	rejectBy: {
		type: [Array, Object],
	},

	dynamicProperties: {
		type: Array,
	},

	groupBy: {
		/*
			Usage:
			{
				property (String): Property to be grouped by, e.g. 'type',
				defaultProperty (String): Objects missing `property` group under this,
				groupKeys (Object): Map grouping keys to custom keys, e.g. {feedback: 'Feedback Required', due, 'Due'}
				groupLabel (Onject): Subheadline of group, e.g. { feedback: 'Please provide feedback' }
				groupSorting (Object): Customize sorting order by ranks, e.g. { old: 1, new: 2 }, default by name asc
				groupClass (Object): Assign class to group, e.g. { error: 'bg-error', success: 'bg-success' }
				order (String): Customize sort order, e.g. 'desc', default 'asc'
			}
		*/
		type: Object,
	},

	sortBy: {
		/*
			Usage:
			{
				property (String|Array): Property (or array of properties) of the object to be sorted by, e.g. 'type',
				direction (String|Array): `asc` for ascending, `desc` for descending sorting of `property`
			}
		*/
		type: Object,
	},

	searchBy: {
		/*
			Usage:
			{
				properties (Array<String>): Properties of the object to be searched by, e.g. ['id', 'name'],
				value (String|Boolean): The value `property` must be equal to
			}
		*/
		type: Object,
	},

	limit: {
		type: Number,
	},

	containerClass: {
		type: String,
		default: 'block',
	},

	options: {
		/* Usage
			{
				group:
				{
					badge (Boolean): default: true,
					divider (Boolean): default: true,
					upper (Boolean): default: true,
					padding (String): default: 'py-4'
				},
				loadingClass (String): loadning symbol, default: 'd-loading d-loading-sm d-loading-spinner d-text-primary',
				header:
				{
					totals (Boolean): Show filtered/total labels (Showing x from y), default: true
				},
				messages: {
					noResult (String): default: 'No result'
				}
			}
		*/
		type: Object,
	},
})

const emit = defineEmits(['loaded'])

const filter = ref([])
const reject = ref({})
const group = ref({})
const search = ref({
	properties: [],
	value: null,
})
const sort = ref({
	property: null,
	direction: 'asc',
})

const localOptions = ref({})
const localData = ref([])

const force = ref(1)
const busy = ref(true)

const filteredData = computed(() => {
	force.value

	// let filtered = localData.value.map((ld) => {
	// 	return { ...ld }
	// })

	let filtered = localData.value

	if (!_.isEmpty(props.dynamicProperties)) {
		filtered = _.map(filtered, (f) => {
			props.dynamicProperties.forEach((dynamicProperty) => {
				if (Array.isArray(dynamicProperty)) {
					if (dynamicProperty.every((d) => _.get(f, d.property) === d.value)) {
						f.dynamicProperty = dynamicProperty[0].dynamicProperty
					}
				} else if (_.get(f, dynamicProperty.property) === dynamicProperty.value) {
					f.dynamicProperty = dynamicProperty.dynamicProperty
				}
			})
			return f
		})
	}

	// Search term
	if (!_.isEmpty(_.get(search.value, 'value'))) {
		filtered = _.filter(filtered, (d) => {
			return search.value.properties.some((property) => {
				const p = _.get(d, property)
				if (Number.isInteger(Number(p))) return p == Number(search.value.value)
				else return p.toLowerCase().indexOf(search.value.value.toLowerCase()) > -1
			})
		})
	}

	// FilterBy
	if (!_.isEmpty(filter.value)) {
		filtered = _.filter(filtered, (d) => {
			if (Array.isArray(filter.value)) {
				// [{}, {}, ...]
				return filter.value.every((fBy) => {
					// { property: ..., value: []} <- value is an array
					if (Array.isArray(fBy.value)) {
						return fBy.value.some((val) => _.get(d, fBy.property).includes(val))
					} else {
						if (Array.isArray(_.get(d, fBy.property))) {
							// { property: [], value: ...} <- value of property is an array
							return _.get(d, fBy.property).includes(fBy.value)
						} else {
							// { property: ..., value: ...} <- both property and value are no arrays
							if (fBy.operator == '==') {
								return _.get(d, fBy.property) == fBy.value
							} else if (fBy.operator == '===') {
								return _.get(d, fBy.property) === fBy.value
							} else if (fBy.operator == '!=') {
								return _.get(d, fBy.property) != fBy.value
							} else if (fBy.operator == '!==') {
								return _.get(d, fBy.property) !== fBy.value
							} else {
								return _.get(d, fBy.property) === fBy.value
							}
						}
					}
				})
			} else {
				// {}
				if (Array.isArray(filter.value.value)) {
					// { property: ..., value: []} <- value is an array
					return filter.value.value.some((val) => _.get(d, filter.value.property).includes(val))
				} else {
					if (Array.isArray(_.get(d, filter.value.property))) {
						// { property: [], value: ...} <- value of property is an array
						return _.get(d, filter.value.property).includes(filter.value.value)
					} else {
						// { property: ..., value: ...} <- both property and value are no arrays
						if (filter.value.operator == '==') {
							return _.get(d, filter.value.property) == filter.value.value
						} else if (filter.value.operator == '===') {
							return _.get(d, filter.value.property) === filter.value.value
						} else if (filter.value.operator == '!=') {
							return _.get(d, filter.value.property) != filter.value.value
						} else if (filter.value.operator == '!==') {
							return _.get(d, filter.value.property) !== filter.value.value
						} else {
							return _.get(d, filter.value.property) === filter.value.value
						}
					}
				}
			}
		})
	}

	// RejectBy
	if (!_.isEmpty(reject.value)) {
		filtered = _.reject(filtered, (d) => {
			return reject.value.values.includes(_.get(d, reject.value.property))
		})
	}

	// Sort By
	if (!_.isEmpty(props.sortBy)) {
		filtered = _.orderBy(filtered, [props.sortBy.property], [props.sortBy.direction])
	}

	// Filter Callback
	filtered = props.filterCallback(filtered)

	// Sorting Callback
	filtered = props.sortingCallback(filtered)

	if (!_.isEmpty(sort.value)) {
		filtered = _.orderBy(filtered, [sort.value.property], [sort.value.direction])
	}
	return filtered
})

const groupedData = computed(() => {
	force.value

	let filtered = filteredData.value

	if (_.isEmpty(group.value)) return _.groupBy(filtered, 'none')

	// Group
	let grouped = _.groupBy(filtered, _.get(group.value, 'property', _.get(group.value, 'defaultProperty', undefined)))

	// Sort
	if (!_.isEmpty(group.value.groupSorting)) {
		grouped = _.chain(grouped)
			.toPairs()
			.sortBy((pair) => group.value.groupSorting[pair[0]])
			.fromPairs()
			.value()
	} else {
		grouped = _.chain(grouped).toPairs().orderBy(0, 'asc').fromPairs().value()
	}

	return grouped
})

const hasData = computed(() => {
	return localData.value ? localData.value.length > 0 : false
})

const hasMatches = computed(() => {
	return filteredData.value.length > 0
})

const addFilter = (property, value, operator = '==') => {
	let filterIndex = getFilterIndex(property)

	if (filterIndex > -1) {
		filter.value[filterIndex].value = value
		filter.value[filterIndex].operator = operator
	} else {
		filter.value.push({ property: property, value: value, operator: operator })
	}
}

const removeFilter = (property, value = null) => {
	let filterIndex = getFilterIndex(property, value)
	if (filterIndex > -1) filter.value.splice(filterIndex, 1)
}

const getFilterIndex = (property, value = null) => {
	return filter.value.findIndex((f) => {
		if (value != null) {
			return f.property === property && f.value === value
		} else {
			return f.property === property
		}
	})
}

const addGrouping = (property, defaultProperty = null) => {
	group.value = { property: property, defaultProperty: defaultProperty }
}

const hasFilter = (property, value = null) => {
	return getFilterIndex(property, value) > -1
}

const hasGrouping = (property) => {
	return group.value.property == property
}

const isFiltered = computed(() => {
	return !_.isEmpty(filter.value)
})

const isGrouped = computed(() => {
	return !_.isEmpty(group.value)
})

const resetGrouping = () => {
	group.value = { property: 'level.name' }
}

const resetFilter = () => {
	search.value.value = null
	filter.value = []
}

const getOption = (key) => {
	return _.get(localOptions.value, key, false)
}

const getGroupClass = (key) => {
	return _.get(_.get(group.value, 'groupClass'), key, null)
}

const getGroupKey = (key) => {
	return _.get(group.value.groupKeys, key, key)
}

const getGroupLabel = (key) => {
	return _.get(group.value.groupLabels, key, null)
}

const getGroupImgSrc = (key) => {
	return _.get(group.value.groupImgSrc, key, null)
}

const fetchDataByUrl = async (isBusy = true) => {
	busy.value = isBusy

	const method = props.fetch.method ? props.fetch.method.toLowerCase() : 'get'

	axios({
		method: method,
		url: props.fetch.url,
		data: props.fetch.data,
		params: props.fetch.data,
	})
		.then((response) => {
			localData.value = response.data
			localData.value = props.dataCallback(localData.value)

			emit('loaded', localData.value)
		})
		.catch((error) => {
			localData.value = []
			console.log(error)
		})
		.finally(() => {
			force.value++
			busy.value = false
		})
}

const fetchDataByProp = (isBusy = true) => {
	busy.value = isBusy
	localData.value = props.data
	localData.value = props.dataCallback(localData.value)
	localData.value.forEach((ld) => (ld.unique = _.uniqueId()))
	force.value++
	busy.value = false

	emit('loaded', localData.value)
}

const refresh = async () => {
	if (_.isEmpty(props.fetch) && !_.isEmpty(props.data)) {
		fetchDataByProp(false)
	}

	if (!_.isEmpty(props.fetch)) {
		await fetchDataByUrl(false)
	}
}

watch(
	() => props.data,
	() => {
		if (_.isEmpty(props.fetch?.url) && props.data) {
			fetchDataByProp()
		}
	},
	{ immediate: true }
)

watch(
	() => props.fetch,
	(oldVal, newVal) => {
		if (!_.isEmpty(props.fetch?.url) && !_.isEqual(oldVal, newVal)) {
			fetchDataByUrl()
		}
	},
	{ immediate: true }
)

watch(
	() => props.filterBy,
	() => {
		filter.value = props.filterBy || []
	},
	{ immediate: true }
)

watch(
	() => props.rejectBy,
	() => {
		reject.value = props.rejectBy || {}
	},
	{ immediate: true }
)

watch(
	() => props.groupBy,
	(oldVal, newVal) => {
		if (!_.isEqual(oldVal, newVal)) {
			group.value = props.groupBy
		}
	},
	{ immediate: true }
)

watch(
	() => props.searchBy,
	(oldVal, newVal) => {
		if (!_.isEqual(oldVal, newVal)) {
			search.value = props.searchBy
		}
	},
	{ immediate: true }
)

watch(
	() => props.options,
	() => {
		localOptions.value = {
			group: {
				badge: true,
				divider: true,
				upper: true,
				class: 'py-4',
			},
			loadingClass: 'd-loading d-loading-sm d-loading-spinner text-primary',
			header: {
				totals: false,
			},
			messages: {
				noResult: '',
			},
		}

		if (!_.isEmpty(props.options)) {
			_.merge(localOptions.value, props.options)
		}
	},
	{ immediate: true }
)

defineExpose({
	localData,
	filteredData,
	groupedData,

	addFilter,
	removeFilter,
	resetFilter,
	hasFilter,
	isFiltered,

	addGrouping,
	resetGrouping,
	hasGrouping,
	isGrouped,

	search,

	hasMatches,
	refresh,
})
</script>
