<template>
	<span class="inline-flex items-center justify-center select-none" :class="[variantClass, sizeClass, colorClass]">
		<slot></slot>
	</span>
</template>

<script>
import { ref, computed } from 'vue'

export default {
	props: {
		variant: {
			type: String,
			default: 'basic',
			validator: function (value) {
				return ['basic', 'rounded'].indexOf(value) !== -1
			},
		},
		size: {
			type: String,
			default: 'xs',
			validator: function (value) {
				return ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].indexOf(value) !== -1
			},
		},
		color: {
			type: String,
			default: 'gray',
			validator: function (value) {
				return ['gray', 'red', 'yellow', 'green', 'blue', 'indigo', 'purple', 'pink', 'white'].indexOf(value) !== -1
			},
		},
	},
	setup(props, ctx) {
		const variantClass = computed(() => {
			switch (props.variant) {
				case 'basic':
					return 'rounded-full'
				case 'rounded':
					return 'rounded-md'
			}
		})

		const sizeClass = computed(() => {
			switch (props.size) {
				case 'xs':
					return 'h-6 w-6 text-xs'
				case 'sm':
					return 'h-8 w-8 text-sm'
				case 'md':
					return 'h-10 w-10 text-base'
				case 'lg':
					return 'h-12 w-12 text-lg'
				case 'xl':
					return 'h-14 w-14 text-xl'
				case 'xxl':
					return 'h-24 w-24 text-3xl'
			}
		})

		const colorClass = computed(() => {
			switch (props.color) {
				case 'gray':
					return 'bg-gray-50 text-gray-400'
				case 'red':
					return 'bg-red-50 text-red-400'
				case 'yellow':
					return 'bg-yellow-50 text-yellow-400'
				case 'green':
					return 'bg-green-50 text-green-400'
				case 'blue':
					return 'bg-blue-50 text-blue-400'
				case 'indigo':
					return 'bg-indigo-50 text-indigo-400'
				case 'purple':
					return 'bg-purple-50 text-purple-400'
				case 'pink':
					return 'bg-pink-50 text-pink-400'
				case 'white':
					return 'bg-white text-gray-400'
			}
		})

		return {
			variantClass,
			sizeClass,
			colorClass,
		}
	},
}
</script>
