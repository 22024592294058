
import {
  TButton,
} from 'vue-tailwind/dist/components'

export default {
  component: TButton,
  props: {
    fixedClasses: 'block focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed',
    classes:      'text-sm text-indigo-600 hover:text-indigo-600',
    variants: {
      pink:           'text-sm text-pink-600 hover:text-pink-700',
      tiny:           'text-xs text-indigo-600 hover:text-indigo-600',
      markImportant:  'text-xs text-gray-500 hover:text-indigo-600',

    }
  }
}
