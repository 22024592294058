<template>
	<span>
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 24 24"
			stroke-width="1.5"
			stroke="currentColor"
			:class="[iconSizeClass, iconColorClass]"
		>
			<path
				v-if="name === 'arrow-up' && type === TYPE_OUTLINE"
				stroke-linecap="round"
				stroke-linejoin="round"
				d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
			/>

			<path
				v-if="name === 'arrow-down' && type === TYPE_OUTLINE"
				fill-rule="evenodd"
				d="M12 2.25a.75.75 0 01.75.75v16.19l6.22-6.22a.75.75 0 111.06 1.06l-7.5 7.5a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 111.06-1.06l6.22 6.22V3a.75.75 0 01.75-.75z"
				clip-rule="evenodd"
			/>

			<path
				v-if="name === 'arrow-long-left' && type === TYPE_OUTLINE"
				stroke-linecap="round"
				stroke-linejoin="round"
				d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
			/>
		</svg>
	</span>
</template>

<script>
import { ref, computed } from 'vue'

export default {
	props: {
		type: {
			type: String,
			default: 'outline',
			validator: function (value) {
				return ['outline', 'solid'].indexOf(value) !== -1
			},
		},
		size: {
			type: String,
			default: 'sm',
			validator: function (value) {
				return ['xs', 'sm', 'base', 'md', 'lg', 'xl'].indexOf(value) !== -1
			},
		},

		color: {
			type: String,
			default: 'black',
			validator: function (value) {
				return ['black', 'white', 'indigo'].indexOf(value) !== -1
			},
		},
		name: {
			type: String,
			validator: function (value) {
				return ['arrow-up', 'arrow-down', 'arrow-long-left'].indexOf(value) !== -1
			},
		},
	},

	setup(props, ctx) {
		const TYPE_OUTLINE = 'outline'
		const TYPE_SOLID = 'solid'

		const iconSizeClass = computed(() => {
			switch (props.size) {
				case 'xs':
					return 'h-2 w-2'
				case 'sm':
					return 'h-3 w-3'
				case 'base':
					return 'h-4 w-4'
				case 'md':
					return 'h-6 w-6'
				case 'lg':
					return 'h-8 w-8'
				case 'xl':
					return 'h-12 w-12'
			}
		})

		const iconColorClass = computed(() => {
			switch (props.color) {
				case 'black':
					return 'text-black'
				case 'white':
					return 'text-white'
				case 'indigo':
					return 'text-indigo-600'
				case 'red':
					return 'text-red-600'
				case 'green':
					return 'text-green-600'
			}
		})

		return {
			TYPE_OUTLINE,
			TYPE_SOLID,

			iconSizeClass,
			iconColorClass,
		}
	},
}
</script>
