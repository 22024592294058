import { render, staticRenderFns } from "./Draggable.vue?vue&type=template&id=1487f88e&scoped=true&"
import script from "./Draggable.vue?vue&type=script&setup=true&lang=js&"
export * from "./Draggable.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./Draggable.vue?vue&type=style&index=0&id=1487f88e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1487f88e",
  null
  
)

export default component.exports