const routes = [
	{
		path: '*',
		name: '404',
		component: () => import('@/apps/Auth/Login.vue'),
		meta: {},
	},

	/* --------------------------------------------------- */
	/* AUTH */
	/* --------------------------------------------------- */
	{
		path: '/login',
		name: 'login',
		meta: {},
		component: () => import('@/apps/Auth/Login.vue'),
	},

	{
		path: '/not-authorized',
		name: 'not-authorized',
		meta: {},
		component: () => import('@/apps/Auth/NotAuthorized.vue'),
	},

	{
		path: '/forgot-password',
		name: 'forgot-password',
		meta: {},
		component: () => import('@/apps/Auth/ForgotPassword.vue'),
	},

	{
		path: '/reset-link-sent',
		name: 'reset-link-sent',
		meta: {},
		component: () => import('@/apps/Auth/ResetLinkSent.vue'),
	},

	{
		path: '/reset-password',
		name: 'reset-password',
		meta: {},
		component: () => import('@/apps/Auth/ResetPassword.vue'),
	},

	/* --------------------------------------------------- */
	/* DASHBOARD */
	/* --------------------------------------------------- */
	{
		path: '/',
		name: 'home',
		component: () => import('@/apps/Dashboard/Home.vue'),
		meta: {
			subject: 'app.ics',
		},
	},

	{
		path: '/ics/dashboard',
		name: 'ics-dashboard',
		component: () => import('@/apps/Dashboard/Home.vue'),
		meta: {
			subject: 'app.ics',
		},
	},

	/* --------------------------------------------------- */
	/* CONTROLS */
	/* --------------------------------------------------- */
	{
		path: '/ics/design',
		name: 'ics-design',
		component: () => import('@/apps/ics/Controls/Home.vue'),
		props: { view: 'design' },
		meta: {
			subject: 'app.ics.controls',
		},
	},

	{
		path: '/ics/design/:controlUuid',
		name: 'ics-design-dynamic',
		component: () => import('@/apps/ics/Controls/Home.vue'),
		props: { view: 'design' },
		meta: {
			subject: 'app.ics.controls',
		},
	},

	{
		path: '/ics/perform',
		name: 'ics-perform',
		component: () => import('@/apps/ics/Controls/Home.vue'),
		props: { view: 'perform' },
		meta: {
			subject: 'app.ics.controls',
		},
	},

	{
		path: '/ics/perform/:controlUuid',
		name: 'ics-perform-dynamic',
		component: () => import('@/apps/ics/Controls/Home.vue'),
		props: { view: 'perform' },
		meta: {
			subject: 'app.ics.controls',
		},
	},

	{
		path: '/ics/perform/:controlUuid/in/:controlInstanceUuid/with/:todoUuid/comments/:commentUuid',
		name: 'ics-perform-comment',
		component: () => import('@/apps/ics/Controls/Home.vue'),
		props: { mention: true },
		meta: {
			subject: 'app.ics.controls',
		},
	},

	/* LIGHT */
	{
		path: '/ics/lite',
		name: 'ics-lite',
		meta: {},
		component: () => import('@/apps/ics/Lite/Home.vue'),
	},

	/* --------------------------------------------------- */
	/* PROCESSES */
	/* --------------------------------------------------- */
	{
		path: '/ics/processes',
		name: 'ics-processes',
		component: () => import('@/apps/Processes/Home.vue'),
		meta: {
			subject: 'app.ics.controls',
		},
	},

	{
		path: '/ics/processes/diagrams/:diagramUuid/activities/:activityId',
		name: 'ics-processes-dynamic',
		component: () => import('@/apps/Processes/Home.vue'),
		meta: {
			subject: 'app.ics.controls',
		},
	},

	/* --------------------------------------------------- */
	/* REPORTING */
	/* --------------------------------------------------- */
	{
		path: '/ics/reports',
		name: 'ics-reporting',
		component: () => import('@/apps/ics/Reporting/Home.vue'),
		props: {
			app: 'ics',
		},
		meta: {
			subject: 'app.ics.reporting',
		},
	},

	/* --------------------------------------------------- */
	/* RISKS */
	/* --------------------------------------------------- */

	{
		path: '/rms',
		name: 'rms-home',
		component: () => import('@/apps/rms/Home.vue'),
		meta: {
			subject: 'app.rms',
		},
	},

	{
		path: '/rms/risks',
		name: 'rms-risks',
		component: () => import('@/apps/rms/views/Risks/Home.vue'),
		meta: {
			subject: 'app.rms',
		},
	},

	{
		path: '/rms/tasks',
		name: 'rms-tasks',
		component: () => import('@/apps/rms/views/Tasks/Home.vue'),
		meta: {
			subject: 'app.rms',
		},
	},

	{
		path: '/rms/tasks/risks/:riskUuid/comments/:commentUuid',
		name: 'rms-tasks-comments',
		component: () => import('@/apps/rms/views/Tasks/Home.vue'),
		meta: {
			subject: 'app.rms',
		},
	},

	{
		path: '/rms/registers',
		name: 'rms-registers',
		component: () => import('@/apps/rms/views/Risks/Home.vue'),
		meta: {
			subject: 'app.rms',
		},
	},

	{
		path: '/rms/heatmap',
		name: 'rms-heatmap',
		component: () => import('@/apps/rms/views/Heatmap/Home.vue'),
		meta: {
			subject: 'app.rms',
		},
	},

	/* ------ */

	/* ------------------------------------------------ */
	/* USERS */
	/* ------------------------------------------------ */
	{
		path: '/users',
		name: 'users',
		component: () => import('@/apps/Users/Home.vue'),
		meta: {
			subject: 'app.users',
		},
	},
]

export default routes
