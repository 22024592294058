import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

import { can } from '@/libs/functions'

Vue.use(VueRouter)

const router = new VueRouter({
	routes,
})

router.beforeEach((to, from, next) => {
	if (
		to.matched.some((route) => {
			if (!route.meta.subject) return false

			if (route.meta.action) {
				return !can(route.meta.action, route.meta.subject)
			} else {
				const canUseRoute = can('use', route.meta.subject)
				const canManageRoute = can('manage', route.meta.subject)
				const canPassRoute = canUseRoute || canManageRoute

				return !canPassRoute
			}
		})
	) {
		return next({ name: 'login', query: { redirect: to.fullPath } })
	}

	return next()
})

export default router
