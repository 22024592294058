import axios from 'axios'
import router from '@/router'

const instance = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	withCredentials: true,
})

instance.interceptors.request.use((config) => {
	config.headers['X-Socket-ID'] = window.Echo.socketId()
	return config
})

instance.interceptors.response.use(
	(response) => {
		return response
	},
	(error) => {
		if (error.response && [401, 419].includes(error.response.status)) {
			router.push({ name: 'login' })
		}

		if (error.response && [403].includes(error.response.status)) {
			router.push({ name: 'login' })
		}

		return Promise.reject(error)
	}
)

export default instance
