<template>
	<div :class="[containerClass, containerBgClass]">
		<slot></slot>
		<p :class="placeholderClass">
			{{ placeholder }}
		</p>
	</div>
</template>

<script>
export default {
	props: {
		placeholder: {
			type: String,
			default: 'No results',
		},
		containerClass: {
			type: String,
			default: 'flex flex-col justify-center items-center rounded-md p-4',
		},
		containerBgClass: {
			type: String,
			default: 'bg-slate-100',
		},
		placeholderClass: {
			type: String,
			default: 'text-sm text-gray-400',
		},
	},
}
</script>
