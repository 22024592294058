<template>
	<div id="app" class="h-full w-full">
		
			<router-view></router-view>
		
	</div>
</template>

<script>
export default {
	setup(props, ctx) {
		return {}
	},
}
</script>
