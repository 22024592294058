
import {
  TRadioGroup,
} from 'vue-tailwind/dist/components'

export default {
  component: TRadioGroup,
  props: {
    classes: {
      groupWrapper: 'flex flex-col space-y-2 select-none',
      label: 'w-full',
      labelChecked: 'w-full',
      wrapper: 'bg-white border border-gray-300 flex items-center px-2 py-2 rounded cursor-pointer focus:shadow-outline text-sm text-gray-700 hover:text-gray-500 leading-5',
      wrapperChecked: 'bg-indigo-50 border border-indigo-600 flex items-center px-2 py-2 rounded cursor-pointer focus:shadow-outline text-sm text-gray-700 hover:text-gray-500 leading-5',
      inputWrapper: '',
      inputWrapperChecked: '',
      input: 'absolute invisible'    
    },
    variants: {
      noPadding: { // used in rms -> scoring
        wrapper: 'bg-slate-100 border border-transparent flex items-center p-0 rounded cursor-pointer focus:shadow-outline text-sm text-gray-700 hover:text-gray-500 leading-5',
        wrapperChecked: 'bg-indigo-50 border border-indigo-600 flex items-center p-0 rounded cursor-pointer focus:shadow-outline text-sm text-gray-700 hover:text-gray-500 leading-5',
  
      }
    }
  }
}
