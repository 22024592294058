<template>
	<div class="flex items-center gap-x-1">
		<div>
			<svg
				v-if="icon === 'hand'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M9 3a1 1 0 012 0v5.5a.5.5 0 001 0V4a1 1 0 112 0v4.5a.5.5 0 001 0V6a1 1 0 112 0v5a7 7 0 11-14 0V9a1 1 0 012 0v2.5a.5.5 0 001 0V4a1 1 0 012 0v4.5a.5.5 0 001 0V3z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'clock'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'clock-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
			</svg>

			<svg
				v-if="icon === 'pencil'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
				/>
			</svg>

			<svg
				v-if="icon === 'pencil-alt'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
				<path
					fill-rule="evenodd"
					d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg v-if="icon === 'location'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
				<path
					fill-rule="evenodd"
					d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'lightning-bolt'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0 01-.82-1.573l7-10a1 1 0 011.12-.38z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'loading'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'paper-clip'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'fire'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M12.395 2.553a1 1 0 00-1.45-.385c-.345.23-.614.558-.822.88-.214.33-.403.713-.57 1.116-.334.804-.614 1.768-.84 2.734a31.365 31.365 0 00-.613 3.58 2.64 2.64 0 01-.945-1.067c-.328-.68-.398-1.534-.398-2.654A1 1 0 005.05 6.05 6.981 6.981 0 003 11a7 7 0 1011.95-4.95c-.592-.591-.98-.985-1.348-1.467-.363-.476-.724-1.063-1.207-2.03zM12.12 15.12A3 3 0 017 13s.879.5 2.5.5c0-1 .5-4 1.25-4.5.5 1 .786 1.293 1.371 1.879A2.99 2.99 0 0113 13a2.99 2.99 0 01-.879 2.121z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'fire-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
				/>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
				/>
			</svg>

			<svg
				v-if="icon === 'add'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>

			<svg
				v-if="icon === 'chevron-left'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
			</svg>

			<svg
				v-if="icon === 'chevron-right'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
			</svg>

			<svg
				v-if="icon === 'chevron-down'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
			</svg>

			<svg
				v-if="icon === 'chevron-up'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
			</svg>
			<svg
				v-if="icon === 'badge-check'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'check-circle'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'check-circle-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
			</svg>

			<svg
				v-if="icon === 'check-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
			</svg>

			<svg
				v-if="icon === 'exclamation'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'exclamation-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
				/>
			</svg>

			<svg
				v-if="icon === 'information-circle'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'information-circle-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>

			<svg
				v-if="icon === 'exclamation-circle'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
					clip-rule="evenodd"
				/>
			</svg>

			<!-- Noun: https://thenounproject.com/term/ghost/166028/ -->
			<svg v-if="icon === 'ghost'" xmlns="http://www.w3.org/2000/svg" :class="cls" viewBox="0 0 100 100">
				<g>
					<path
						d="m93.898 77.801s-1.8008-4.3008-4.1016-10.398c-2.3008-6.1016-1.6016-5.3008-5-31.5-3.5-27.301-32.602-30-32.602-30-10.898 0.80078-20.398 6.6016-24.398 10.301-3.9961 3.6953-11.695 11.898-11.898 31.195-0.19922 19.301-5.1016 27.5-7.5 34.301-1.1992 3.5-0.19922 4.6992 2.1992 4.6992 2.1992 0 5.6016-1 9.3984-2 3.0039-0.89844 4.7031-1.3984 6.0039-1.3984 2.1992 0 3 1.5 5.8008 5.6016 2.3008 3.3984 3.8984 4.6016 6.1016 4.6016 2 0 4.5-1 8.6016-2.5 3-1 5.3984-1.3984 7.3984-1.3984 3.8984 0 6.5 1.5 9.3008 3.3008 2.1992 1.3008 3.8008 2.5 5.5 2.5s3.3008-1.1016 5.6016-4.1016c4.5-6.1016 5-6 14.398-6.1016 9.4961-0.30078 5.1953-7.1016 5.1953-7.1016zm-1.6992 3.5c-0.10156 0.19922-1 0.80078-3.5 0.80078h-0.80078c-4.6016 0.10156-7.1992 0.10156-9.5 1.1016-2.5 1.1016-4 3-6.3008 6.1016-1.3984 2-2.6016 3-3.3984 3-0.80078 0-2.1016-0.80078-3.6016-1.6992-0.19922-0.10156-0.39844-0.19922-0.5-0.30078-2.6016-1.6016-5.8984-3.6992-10.699-3.6992-2.5 0-5.1992 0.5-8.3008 1.6016-0.69922 0.30078-1.3984 0.5-2 0.69922-2.6016 0.89844-4.5 1.6016-5.6992 1.6016-0.80078 0-1.6992-0.30078-3.8984-3.5-0.30078-0.39844-0.60156-0.80078-0.80078-1.1992-1.1992-1.6992-2-2.8984-2.8984-3.8008-1.1992-1.1992-2.6016-1.6992-4.3008-1.6992-1.6016 0-3.3008 0.5-6.1016 1.1992l-0.5 0.10156c-0.39844 0.10156-0.89844 0.19922-1.3008 0.39844-3 0.80078-5.8008 1.6016-7.3984 1.6016 0-0.30078 0.10156-0.69922 0.30078-1.1992 0.39844-1.1992 0.89844-2.5 1.5-3.8008 2.5-6.3984 6-15.102 6.1016-31.301 0.10156-7.8984 1.5-14.699 4.3008-20.301 2.1992-4.5 5-7.3008 6.8008-9 2.1016-2 5.5-4.1016 8.8984-5.8008 4.3984-1.9062 9.0977-3.207 13.598-3.6055 1.1992 0.10156 7.3008 1 13.801 4.3984 4.1992 2.1992 7.6016 5 10.199 8.3008 3.3008 4.1016 5.3008 9.1992 6 14.898 0.89844 7 1.5 12.102 1.8984 15.898 1.1992 10.301 1.3984 11.398 3.1992 16.199 2.3008 6.1016 4.1992 10.5 4.1992 10.5 0.10156 0.10156 0.10156 0.19922 0.19922 0.39844 0.40625 0.70313 0.70313 1.8047 0.50391 2.1055z"
					/>
					<path d="m45.398 37.898c0 4.8008-7.1992 4.8008-7.1992 0 0-4.7969 7.1992-4.7969 7.1992 0" />
					<path d="m57.102 37.898c0 4.8008-7.2031 4.8008-7.2031 0 0-4.7969 7.2031-4.7969 7.2031 0" />
					<path
						d="m56 25.5c-3.5 0-6.6016 2.1016-8.1992 5.3984-1.6016-3.3008-4.6992-5.3984-8.1992-5.3984-5.1992 0-9.3984 4.8008-9.3984 10.801-0.003906 6 4.1953 10.699 9.3984 10.699 3.5 0 6.6016-2.1016 8.1992-5.3984 1.6016 3.3008 4.6992 5.3984 8.1992 5.3984 5.1992 0 9.3984-4.8008 9.3984-10.801s-4.1992-10.699-9.3984-10.699zm0 19.5c-3.3984 0-6.3984-2.6992-7.1992-6.6016-0.10156-0.5-0.5-0.80078-1-0.80078s-0.89844 0.30078-1 0.80078c-0.80078 3.9023-3.8008 6.6016-7.1992 6.6016-4.1016 0-7.5-3.8984-7.5-8.8008 0-4.8984 3.3008-8.8008 7.5-8.8008 3.3984 0 6.3984 2.6992 7.1992 6.6016 0.10156 0.5 0.5 0.80078 1 0.80078s0.89844-0.30078 1-0.80078c0.80078-3.8984 3.8008-6.6016 7.1992-6.6016 4.1016 0 7.5 3.8984 7.5 8.8008 0 4.9023-3.3984 8.8008-7.5 8.8008z"
					/>
				</g>
			</svg>

			<svg
				v-if="icon === 'inbox'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
				/>
			</svg>

			<svg
				v-if="icon === 'ban'"
				:class="cls"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'adjustments'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z"
				/>
			</svg>

			<svg
				v-if="icon === 'adjustments-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
				/>
			</svg>

			<svg
				v-if="icon === 'view-grid'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
				/>
			</svg>

			<svg
				v-if="icon === 'close'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'chat'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 100 100"
				fill="currentColor"
			>
				<path
					d="m50 8.8789c-26.469 0-48 16.781-48 37.41 0 9.2891 4.3086 18.07 12.18 24.93l-3.1016 22.223 26.922-10.93c3.9492 0.78906 7.9688 1.1875 12 1.1875 26.469 0 48-16.781 48-37.41 0-20.629-21.531-37.41-48-37.41zm0 66.82c-3.8594-0.003907-7.7109-0.42578-11.48-1.2578l-1.2109-0.26953-16.387 6.6367 1.8086-13-1.8086-1.4102c-7.043-5.5-10.922-12.648-10.922-20.109 0-16.219 17.941-29.41 40-29.41s40 13.191 40 29.41c0 16.223-17.941 29.41-40 29.41z"
				/>
			</svg>

			<svg
				v-if="icon === 'chat-alt-2'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					stroke-width="2"
					d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
				/>
			</svg>

			<svg
				v-if="icon === 'chat-alt-2-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
				/>
			</svg>

			<svg
				v-if="icon === 'paper-airplane'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
				/>
			</svg>

			<svg
				v-if="icon === 'user-add'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"
				/>
			</svg>

			<svg
				v-if="icon === 'user-remove'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path d="M11 6a3 3 0 11-6 0 3 3 0 016 0zM14 17a6 6 0 00-12 0h12zM13 8a1 1 0 100 2h4a1 1 0 100-2h-4z" />
			</svg>

			<svg
				v-if="icon === 'user-circle'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'user'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
			</svg>

			<svg
				v-if="icon === 'user-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
				/>
			</svg>

			<svg
				v-if="icon === 'users'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"
				/>
			</svg>

			<svg
				v-if="icon === 'users-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
				/>
			</svg>

			<svg
				v-if="icon === 'collection'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"
				/>
			</svg>

			<svg
				v-if="icon === 'collection-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
				/>
			</svg>

			<svg
				v-if="icon === 'star'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z"
				/>
			</svg>

			<svg
				v-if="icon === 'star-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
				/>
			</svg>

			<svg
				v-if="icon === 'strike-through'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					d="m21.41 75.348c-0.39062 0.003906-0.76562-0.14453-1.0508-0.41016-0.58984-0.60938-0.58984-1.5703 0-2.1797l57.168-57.18c0.58203-0.57812 1.5234-0.57812 2.1055 0 0.30859 0.27734 0.48438 0.67578 0.48438 1.0898 0 0.41797-0.17578 0.8125-0.48438 1.0898l-57.105 57.18c-0.30469 0.27734-0.70703 0.42578-1.1172 0.41016z"
				/>
			</svg>

			<svg
				v-if="icon === 'arrow-circle-up'"
				:class="cls"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L9 9.414V13a1 1 0 102 0V9.414l1.293 1.293a1 1 0 001.414-1.414z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'arrow-circle-down'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v3.586L7.707 9.293a1 1 0 00-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 10.586V7z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'arrow-circle-right'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-8.707l-3-3a1 1 0 00-1.414 1.414L10.586 9H7a1 1 0 100 2h3.586l-1.293 1.293a1 1 0 101.414 1.414l3-3a1 1 0 000-1.414z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'arrow-circle-left'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'arrow-circle-down-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"
				/>
			</svg>

			<svg
				v-if="icon === 'arrow-circle-right-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>

			<svg
				v-if="icon === 'arrow-down-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M19 14l-7 7m0 0l-7-7m7 7V3" />
			</svg>

			<svg
				v-if="icon === 'plus-circle'"
				:class="cls"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'plus'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'plus-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" />
			</svg>

			<svg
				v-if="icon === 'lock-closed'"
				:class="cls"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'lock-open'"
				:class="cls"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					d="M10 2a5 5 0 00-5 5v2a2 2 0 00-2 2v5a2 2 0 002 2h10a2 2 0 002-2v-5a2 2 0 00-2-2H7V7a3 3 0 015.905-.75 1 1 0 001.937-.5A5.002 5.002 0 0010 2z"
				/>
			</svg>

			<svg
				v-if="icon === 'dots-vertical'"
				:class="cls"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
			</svg>

			<svg
				v-if="icon === 'dots-horizontal'"
				:class="cls"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z" />
			</svg>

			<svg
				v-if="icon === 'bell'"
				:class="cls"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"
				/>
			</svg>

			<svg
				v-if="icon === 'bell-2'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
				/>
			</svg>

			<svg
				v-if="icon === 'academic-cap'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z"
				/>
			</svg>

			<svg
				v-if="icon === 'zoom-in'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path d="M5 8a1 1 0 011-1h1V6a1 1 0 012 0v1h1a1 1 0 110 2H9v1a1 1 0 11-2 0V9H6a1 1 0 01-1-1z" />
				<path
					fill-rule="evenodd"
					d="M2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8zm6-4a4 4 0 100 8 4 4 0 000-8z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'zoom-out'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
					clip-rule="evenodd"
				/>
				<path fill-rule="evenodd" d="M5 8a1 1 0 011-1h4a1 1 0 110 2H6a1 1 0 01-1-1z" clip-rule="evenodd" />
			</svg>

			<svg
				v-if="icon === 'arrow-expand'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 01-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 110-2h4a1 1 0 011 1v4a1 1 0 11-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 112 0v1.586l2.293-2.293a1 1 0 011.414 1.414L6.414 15H8a1 1 0 110 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 110-2h1.586l-2.293-2.293a1 1 0 011.414-1.414L15 13.586V12a1 1 0 011-1z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'undo'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 24 24"
				fill="currentColor"
			>
				<path
					d="M17.026 22.957c10.957-11.421-2.326-20.865-10.384-13.309l2.464 2.352h-9.106v-8.947l2.232 2.229c14.794-13.203 31.51 7.051 14.794 17.675z"
				/>
			</svg>

			<svg
				v-if="icon === 'redo'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 24 24"
				fill="currentColor"
			>
				<path
					d="M6.974 22.957c-10.957-11.421 2.326-20.865 10.384-13.309l-2.464 2.352h9.106v-8.947l-2.232 2.229c-14.794-13.203-31.51 7.051-14.794 17.675z"
				/>
			</svg>

			<svg
				v-if="icon === 'draft'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 24 24"
				fill="currentColor"
			>
				<path
					d="M24 23h-20c-2.208 0-4-1.792-4-4v-15.694c.313-1.071 1.285-2.306 3-2.306 1.855 0 2.769 1.342 2.995 2.312l.005 1.688h18v18zm-1-17h-17v13s-.665-1-2-1c-1.104 0-2 .896-2 2s.896 2 2 2h19v-16zm-18-2.68c-.427-.971-1.327-1.325-2-1.32-.661.005-1.568.3-2 1.32v16.178c.394-1.993 2.245-2.881 4-2.401v-13.777zm15 15.68h-12v-10h12v10zm-8-9h-3v8h10v-4h-2v3h-1v-3h-3v3h-1v-7zm7 0h-6v3h6v-3z"
				/>
			</svg>

			<svg v-if="icon === 'draft2'" :class="cls" viewBox="0 0 100 100" fill="currentColor">
				<g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<rect id="Rectangle" stroke="#4F46E5" stroke-width="3" x="57" y="33" width="56" height="35" rx="4"></rect>
					<circle id="Oval" stroke="#000000" stroke-width="3" cx="8" cy="50" r="17"></circle>
					<path
						id="Line"
						d="M48,46 L57,50.5 L48,55 L48,51 L24,51 L24,50 L48,50 L48,46 Z"
						fill="#000000"
						fill-rule="nonzero"
					></path>
				</g>
			</svg>

			<svg
				v-if="icon === 'x-circle'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>

			<svg
				v-if="icon === 'x-circle-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'document-report'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
				/>
			</svg>

			<svg
				v-if="icon === 'minus'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path fill-rule="evenodd" d="M3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
			</svg>

			<svg
				v-if="icon === 'minus-circle'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'speakerphone'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'speakerphone-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
				/>
			</svg>

			<svg
				v-if="icon === 'calendar-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
				/>
			</svg>

			<svg
				v-if="icon === 'hashtag-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14" />
			</svg>

			<svg
				v-if="icon === 'search'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'flag'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'flag-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M3 21v-4m0 0V5a2 2 0 012-2h6.5l1 1H21l-3 6 3 6h-8.5l-1-1H5a2 2 0 00-2 2zm9-13.5V9"
				/>
			</svg>

			<svg v-if="icon === 'x'" xmlns="http://www.w3.org/2000/svg" :class="cls" viewBox="0 0 20 20" fill="currentColor">
				<path
					fill-rule="evenodd"
					d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'filter'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'filter-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
				/>
			</svg>

			<svg
				v-if="icon === 'location-marker-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
				/>
				<path stroke-linecap="round" stroke-linejoin="round" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
			</svg>

			<svg
				v-if="icon === 'login-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
				/>
			</svg>

			<svg
				v-if="icon === 'logout-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
				/>
			</svg>

			<svg
				v-if="icon === 'color-swatch-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
				/>
			</svg>

			<svg
				v-if="icon === 'bar-chart-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
				/>
			</svg>

			<svg
				v-if="icon === 'emoji-sad-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>

			<svg
				v-if="icon === 'arrow-narrow-right-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
			</svg>

			<svg
				v-if="icon === 'arrow-narrow-left-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
			</svg>

			<svg
				v-if="icon === 'share-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
				/>
			</svg>

			<svg
				v-if="icon === 'minus-circle-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
			</svg>

			<svg
				v-if="icon === 'plus-circle-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>

			<svg
				v-if="icon === 'photograph-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
				/>
			</svg>

			<svg
				v-if="icon === 'reply-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M3 10h10a8 8 0 018 8v2M3 10l6 6m-6-6l6-6" />
			</svg>

			<svg
				v-if="icon === 'home'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
				/>
			</svg>

			<svg
				v-if="icon === 'home-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
				/>
			</svg>

			<svg
				v-if="icon === 'template-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
				/>
			</svg>

			<svg
				v-if="icon === 'dots-circle-horizontal-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>

			<svg
				v-if="icon === 'document-duplicate-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M8 7v8a2 2 0 002 2h6M8 7V5a2 2 0 012-2h4.586a1 1 0 01.707.293l4.414 4.414a1 1 0 01.293.707V15a2 2 0 01-2 2h-2M8 7H6a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2v-2"
				/>
			</svg>

			<svg
				v-if="icon === 'arrow-sm-up-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M7 11l5-5m0 0l5 5m-5-5v12" />
			</svg>

			<svg
				v-if="icon === 'arrow-sm-down-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M17 13l-5 5m0 0l-5-5m5 5V6" />
			</svg>

			<svg
				v-if="icon === 'arrow-sm-right-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M17 8l4 4m0 0l-4 4m4-4H3" />
			</svg>

			<svg
				v-if="icon === 'arrow-sm-leftoutline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M11 17l-5-5m0 0l5-5m-5 5h12" />
			</svg>

			<svg
				v-if="icon === 'view-list'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'thumb-up-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M14 10h4.764a2 2 0 011.789 2.894l-3.5 7A2 2 0 0115.263 21h-4.017c-.163 0-.326-.02-.485-.06L7 20m7-10V5a2 2 0 00-2-2h-.095c-.5 0-.905.405-.905.905 0 .714-.211 1.412-.608 2.006L7 11v9m7-10h-2M7 20H5a2 2 0 01-2-2v-6a2 2 0 012-2h2.5"
				/>
			</svg>

			<svg
				v-if="icon === 'download'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'folder-download-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M12 10v6m0 0l-3-3m3 3l3-3M3 17V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
				/>
			</svg>

			<svg
				v-if="icon === 'document-download-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
				/>
			</svg>

			<svg
				v-if="icon === 'eye-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
				/>
			</svg>

			<svg
				v-if="icon === 'eye-off-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
				/>
			</svg>

			<svg
				v-if="icon === 'support-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z"
				/>
			</svg>

			<svg
				v-if="icon === 'rocket-new'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
				/>
			</svg>

			<svg
				v-if="icon === 'bolt-new'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z"
				/>
			</svg>

			<svg
				v-if="icon === 'trend-up-new'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
				/>
			</svg>

			<svg
				v-if="icon === 'trash-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
				/>
			</svg>

			<svg
				v-if="icon === 'sort-ascending-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12" />
			</svg>

			<svg
				v-if="icon === 'hand-raised-outline'"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				:class="cls"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M10.05 4.575a1.575 1.575 0 10-3.15 0v3m3.15-3v-1.5a1.575 1.575 0 013.15 0v1.5m-3.15 0l.075 5.925m3.075.75V4.575m0 0a1.575 1.575 0 013.15 0V15M6.9 7.575a1.575 1.575 0 10-3.15 0v8.175a6.75 6.75 0 006.75 6.75h2.018a5.25 5.25 0 003.712-1.538l1.732-1.732a5.25 5.25 0 001.538-3.712l.003-2.024a.668.668 0 01.198-.471 1.575 1.575 0 10-2.228-2.228 3.818 3.818 0 00-1.12 2.687M6.9 7.575V12m6.27 4.318A4.49 4.49 0 0116.35 15m.002 0h-.002"
				/>
			</svg>

			<svg
				v-if="icon === 'finger-print-outline'"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				:class="cls"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33"
				/>
			</svg>

			<svg
				v-if="icon === 'globe-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
				/>
			</svg>

			<svg
				v-if="icon === 'play'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
					clip-rule="evenodd"
				/>
			</svg>

			<svg
				v-if="icon === 'arrow-right-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
			</svg>

			<svg
				v-if="icon === 'duplicate-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
				/>
			</svg>

			<svg
				v-if="icon === 'user-group-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
				/>
			</svg>

			<svg
				v-if="icon === 'briefcase-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
				/>
			</svg>

			<svg
				v-if="icon === 'external-link-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
				/>
			</svg>

			<svg
				v-if="icon === 'mail-outline'"
				xmlns="http://www.w3.org/2000/svg"
				:class="cls"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
				/>
			</svg>

			<svg
				v-if="icon === 'chat-bubble-oval-left'"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				:class="cls"
			>
				<path
					stroke-linecap="round"
					stroke-linejoin="round"
					d="M12 20.25c4.97 0 9-3.694 9-8.25s-4.03-8.25-9-8.25S3 7.444 3 12c0 2.104.859 4.023 2.273 5.48.432.447.74 1.04.586 1.641a4.483 4.483 0 01-.923 1.785A5.969 5.969 0 006 21c1.282 0 2.47-.402 3.445-1.087.81.22 1.668.337 2.555.337z"
				/>
			</svg>

			<svg
				v-if="icon === 'arrow-long-up'"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				:class="cls"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18" />
			</svg>

			<svg
				v-if="icon === 'arrow-long-right'"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				:class="cls"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
			</svg>

			<svg
				v-if="icon === 'arrow-long-down'"
				xmlns="http://www.w3.org/2000/svg"
				fill="none"
				viewBox="0 0 24 24"
				stroke-width="1.5"
				stroke="currentColor"
				:class="cls"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3" />
			</svg>
		</div>

		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		cls: {
			type: [String, Object, Array],
			default: 'h-5 w-5 text-inherit',
		},
		size: {
			type: String,
			default: 'basic',
			validator: function (value) {
				return ['small', 'basic', 'large'].indexOf(value) !== -1
			},
		},
		color: {
			type: String,
			default: 'gray',
			validator: function (value) {
				return ['gray', 'red', 'yellow', 'green', 'blue', 'indigo', 'purple', 'pink'].indexOf(value) !== -1
			},
		},
		icon: {
			type: String,
		},
	},

	setup(props, ctx) {
		return {}
	},
}
</script>
