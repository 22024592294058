import moment from 'moment'
import _ from 'lodash'

export const capitalize = (value) => {
	if (!value) return ''
	value = value.toString()
	return value.charAt(0).toUpperCase() + value.slice(1)
}

export const date = (value) => {
	if (!value) return ''
	return moment(String(value)).format('MMM DD, YY')
}

export const time = (value) => {
	if (!value) return ''
	return moment(String(value)).format('hh:mm a')
}

export const dateTime = (value) => {
	if (!value) return ''
	return moment(String(value)).format('MMM DD, YY hh:mm a')
}

export const longDateTime = (value) => {
	if (!value) return ''
	return moment(String(value)).format('ddd, MMM Do YYYY, h:mm:ss a')
}

export const quarter = (value) => {
	if (!value) return ''
	return moment(String(value)).quarter()
}

export const year = (value) => {
	if (!value) return ''
	return moment(String(value)).year()
}

export const trunc = (value, length = 30) => {
	if (!value) return ''
	return _.truncate(value, { length: length })
}

export const upperFirst = (value) => {
	if (!value) return ''
	return _.upperFirst(value)
}

export const lowerFirst = (value) => {
	if (!value) return ''
	return _.lowerFirst(value)
}
