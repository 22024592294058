<template>
	<div :class="containerClass">
		<div
			v-for="(d, index) in data"
			:key="index"
			class="overflow-hidden"
			:class="[tileClass, d.focus ? focusClass : null, { 'cursor-not-allowed': d.disabled }]"
			@click="!d.disabled && d.link ? d.link() : null"
		>
			<div :class="[labelClass]">{{ d.label }}</div>
			<div :class="[valueClass]">
				<template v-if="d.value">{{ d.value | upperFirst }}</template>
				<template v-else>-</template>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		data: {
			type: [Array, Object],
		},
		containerClass: {
			type: String,
			default: 'grid grid-cols-1 sm:grid-cols-3 gap-3',
		},
		tileClass: {
			type: String,
			default: 'bg-slate-100 p-2',
		},
		labelClass: {
			type: String,
			default: 'label line-clamp-1',
		},
		valueClass: {
			type: String,
			default: 'mt-1 tracking-tight font-semibold text-gray-900 line-clamp-1',
		},
		focusClass: {
			type: String,
			default: 'ring-1 ring-indigo-600',
		},
	},
}
</script>
