-
<template>
	<div v-if="busy" class="flex justify-center mt-8">
		<span :class="getOption('loadingClass')"></span>
	</div>
	<div v-else>
		<slot
			name="default"
			:data="localData"
			:filteredData="filteredData"
			:groupedData="groupedData"
			:hasFilter="hasFilter"
			:hasData="hasData"
			:hasMatches="hasMatches"
		></slot>

		<ol v-if="hasMatches" class="space-y-4">
			<li v-for="(group, key) in groupedData" :key="key">
				<div :class="getOption('group.padding')" v-if="key !== 'undefined'">
					<div class="flex items-center gap-2">
						<p class="text-xl font-bold text-black">
							<template v-if="getOption('group.upper')">{{ getGroupKey(key) | upperFirst }}</template>
							<template v-else>{{ key }}</template>
						</p>
						<div class="d-badge d-badge-accent" v-if="getOption('group.badge')">{{ group.length }}</div>
					</div>
					<p class="text-sm">{{ getGroupLabel(key) }}</p>
				</div>
				<ul :class="[containerClass, getGroupClass(key)]">
					<li v-for="(d, index) in group" :key="d.unique">
						<slot name="data" :data="d" :index="index"></slot>
					</li>
				</ul>
			</li>
		</ol>

		<div class="h-full" v-else>
			<slot name="no-result" :hasData="hasData"> </slot>
		</div>

		<div class="h-full" v-if="!hasMatches && hasData">
			<slot name="no-matches"> </slot>
		</div>

		<div class="h-full" v-if="!hasData">
			<slot name="no-data"> </slot>
		</div>
	</div>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import axios from '@/libs/axios'

const props = defineProps({
	data: {
		type: [Object, Array],
	},

	fetch: {
		type: Object,
	},

	/**
	 * Filters list by `property` equals `value`. If array is provided
	 * every entry must match (AND)
	 *
	 * @param {String} property The property to filter by
	 * @param {String|Boolean} value The value to search for
	 * @param {String} or
	 * @example
	 *
	 * { property: deactivated, value: true }
	 * [ { property: deactivated, value: true}. { property: new, value: false} ]
	 *
	 */
	filterBy: {
		type: [Array, Object],
	},

	dynamicProperties: {
		type: Array,
	},

	groupBy: {
		/*
			Usage:
			{
				property (String): Property to be grouped by, e.g. 'type',
				defaultProperty (String): Objects missing `property` group under this,
				groupKeys (Object): Map grouping keys to custom keys, e.g. {feedback: 'Feedback Required', due, 'Due'}
				groupLabel (Onject): Subheadline of group, e.g. { feedback: 'Please provide feedback' }
				groupSorting (Object): Customize sorting order by ranks, e.g. { old: 1, new: 2 }, default by name asc
				groupClass (Object): Assign class to group, e.g. { error: 'bg-error', success: 'bg-success' }
				order (String): Customize sort order, e.g. 'desc', default 'asc'
			}
		*/
		type: Object,
	},

	sortBy: {
		/*
			Usage:
			{
				property (String): Property of the object to be sorted by, e.g. 'type',
				direction (Stirng): `asc` for ascending, `desc` for descending sorting of `property`
			}
		*/
		type: Object,
	},

	searchBy: {
		/*
			Usage:
			{
				property (Array<String>): Properties of the object to be searched by, e.g. ['id', 'name'],
				value (Stirng|Boolean): The value `property` must be equal to
			}
		*/
		type: Object,
	},

	containerClass: {
		type: String,
		default: 'rysqer-tile-grid',
	},

	options: {
		/* Usage
			{
				group:
				{
					badge (Boolean): default: true,
					divider (Boolean): default: true,
					upper (Boolean): default: true,
					padding (String): default: 'py-4'
				},
				loadingClass (String): loadning symbol, default: 'd-loading d-loading-sm d-loading-spinner d-text-primary'
			}
		*/
		type: Object,
	},
})

const localOptions = ref({})
const localData = ref([])

const force = ref(1)
const busy = ref(true)

const filteredData = computed(() => {
	force.value

	let filtered = JSON.parse(JSON.stringify(localData.value))

	if (!_.isEmpty(props.dynamicProperties)) {
		filtered = _.map(filtered, (f) => {
			props.dynamicProperties.forEach((dynamicProperty) => {
				if (Array.isArray(dynamicProperty)) {
					if (dynamicProperty.every((d) => _.get(f, d.property) === d.value)) {
						f.dynamicProperty = dynamicProperty[0].dynamicProperty
					}
				} else if (_.get(f, dynamicProperty.property) === dynamicProperty.value) {
					f.dynamicProperty = dynamicProperty.dynamicProperty
				}
			})
			return f
		})
	}

	// Search term
	if (!_.isEmpty(_.get(props.searchBy, 'value'))) {
		filtered = _.filter(filtered, (d) => {
			return props.searchBy.properties.some((property) => {
				const p = _.get(d, property)
				if (Number.isInteger(Number(p))) return p == Number(props.searchBy.value)
				else return p.toLowerCase().indexOf(props.searchBy.value.toLowerCase()) > -1
			})
		})
	}

	// FilterBy
	if (!_.isEmpty(props.filterBy)) {
		filtered = _.filter(filtered, (d) => {
			if (Array.isArray(props.filterBy)) {
				return props.filterBy.every((filterBy) => {
					if (Array.isArray(filterBy.value)) {
						return filterBy.value.some((val) => _.get(d, filterBy.property).includes(val))
					} else {
						return _.get(d, filterBy.property) === filterBy.value
					}
				})
			} else {
				if (Array.isArray(props.filterBy.value)) {
					return props.filterBy.value.some((val) => _.get(d, props.filterBy.property).includes(val))
				} else {
					return _.get(d, props.filterBy.property) === props.filterBy.value
				}
			}
		})
	}

	// Sort By
	if (!_.isEmpty(props.sortBy)) {
		filtered = _.orderBy(filtered, [props.sortBy.property], [props.sortBy.direction])
	}

	return filtered
})

const groupedData = computed(() => {
	force.value

	let filtered = filteredData.value

	if (_.isEmpty(props.groupBy)) return _.groupBy(filtered, 'none')

	// Group
	let grouped = _.groupBy(
		filtered,
		_.get(props.groupBy, 'property', _.get(props.groupBy, 'defaultProperty', undefined))
	)

	// Sort
	if (!_.isEmpty(props.groupBy.groupSorting)) {
		grouped = _.chain(grouped)
			.toPairs()
			.sortBy((pair) => props.groupBy.groupSorting[pair[0]])
			.fromPairs()
			.value()
	} else {
		grouped = _.chain(grouped).toPairs().orderBy(0, 'asc').fromPairs().value()
	}

	// Key
	/* if (!_.isEmpty(props.groupBy.groupKeys)) {
		grouped = _.mapKeys(grouped, (value, key) => {
			value.originalKey = key
			return _.get(props.groupBy.groupKeys, key, key)
		})
	} */

	return grouped
})

const hasData = computed(() => {
	return localData.value ? localData.value.length > 0 : false
})

const hasFilter = computed(() => {
	return filteredData.value ? filteredData.value.length < localData.value.length : false
})

const hasMatches = computed(() => {
	return filteredData.value.length > 0
})

const getOption = (key) => {
	return _.get(localOptions.value, key, false)
}

const getGroupClass = (key) => {
	return _.get(_.get(props.groupBy, 'groupClass'), key, null)
}

const getGroupKey = (key) => {
	return _.get(props.groupBy.groupKeys, key, key)
}

const getGroupLabel = (key) => {
	return _.get(props.groupBy.groupLabels, key, null)
}

const fetchDataByUrl = async () => {
	busy.value = true
	let response = []
	if (props.fetch.method.toLowerCase() == 'get') {
		response = await axios({
			method: 'get',
			url: props.fetch.url,
			params: props.fetch.data,
		})
	}

	if (props.fetch.method.toLowerCase() == 'post') {
		response = await axios({
			method: 'post',
			url: props.fetch.url,
			data: props.fetch.data,
		})
	}

	localData.value = response.data
	force.value++
	busy.value = false
}

const fetchDataByProp = () => {
	busy.value = true
	localData.value = props.data
	localData.value.forEach((ld) => (ld.unique = _.uniqueId()))
	force.value++
	busy.value = false
}

const refresh = () => {
	console.log('Refreshing tiles ...')

	if (_.isEmpty(props.fetch) && !_.isEmpty(props.data)) {
		fetchDataByProp()
	}

	if (!_.isEmpty(props.fetch)) {
		fetchDataByUrl()
	}
}

watch(
	() => props.data,
	() => {
		if (_.isEmpty(props.fetch) && props.data) {
			fetchDataByProp()
		}
	},
	{ immediate: true }
)

watch(
	() => props.fetch,
	(oldVal, newVal) => {
		if (!_.isEmpty(props.fetch) && !_.isEqual(oldVal, newVal)) {
			fetchDataByUrl()
		}
	},
	{ immediate: true }
)

watch(
	() => props.options,
	() => {
		localOptions.value = {
			group: {
				badge: true,
				divider: true,
				upper: true,
				padding: 'py-4',
			},
			loadingClass: 'd-loading d-loading-sm d-loading-spinner text-primary',
		}

		if (!_.isEmpty(props.options)) {
			_.merge(localOptions.value, props.options)
		}
	},
	{ immediate: true }
)

defineExpose({
	localData,
	filteredData,
	groupedData,
	hasFilter,
	hasMatches,
	refresh,
})
</script>
