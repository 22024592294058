import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useDraggableStore = defineStore('global-draggable', () => {
	const globalZIndex = ref(60)

	const getNextZIndex = () => {
		globalZIndex.value += 1
		return globalZIndex.value
	}
	return {
		globalZIndex,
		getNextZIndex
	}
})
