import {
  TRichSelect,
} from 'vue-tailwind/dist/components'

export default {
  component: TRichSelect,
  props: {
    fixedClasses: {
      wrapper: 'relative',
      buttonWrapper: 'inline-block relative w-full',
      selectButton: 'w-full flex text-left justify-between items-center p-3 text-black transition duration-100 ease-in-out border rounded-lg focus:border-primary focus:ring-2 focus:ring-primary focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed',
      selectButtonLabel: 'block truncate',
      selectButtonPlaceholder: 'block truncate',
      selectButtonIcon: 'fill-current flex-shrink-0 ml-1 h-4 w-4',
      selectButtonClearButton: 'rounded flex flex-shrink-0 items-center justify-center absolute right-0 top-1 m-2 h-6 w-6 transition duration-100 ease-in-out',
      selectButtonClearIcon: 'fill-current h-3 w-3',
      selectButtonTagWrapper: 'flex flex-wrap overflow-hidden',
      selectButtonTag: 'bg-primary block disabled:cursor-not-allowed disabled:opacity-50 duration-100 ease-in-out focus:border-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 rounded shadow-sm text-sm text-white transition white-space-no m-0.5 max-w-full overflow-hidden h-8 flex items-center',
      selectButtonTagText: 'px-3',
      selectButtonTagDeleteButton: '-ml-1.5 h-full hover:bg-primary hover:shadow-sm inline-flex items-center px-2 transition',
      selectButtonTagDeleteButtonIcon: 'w-3 h-3',
      dropdown: 'absolute w-full z-10 -mt-1.5 absolute border-b border-l border-r rounded-b-lg shadow-xl z-10',
      dropdownFeedback: '',
      loadingMoreResults: '',
      optionsList: 'overflow-auto',
      searchWrapper: 'inline-block w-full',
      searchBox: 'inline-block w-full',
      optgroup: '',
      option: 'cursor-pointer',
      disabledOption: 'opacity-50 cursor-not-allowed',
      highlightedOption: 'cursor-pointer',
      selectedOption: 'cursor-pointer',
      selectedHighlightedOption: 'cursor-pointer',
      optionContent: '',
      optionLabel: 'truncate block',
      selectedIcon: 'fill-current h-4 w-4',
      enterClass: '',
      enterActiveClass: '',
      enterToClass: '',
      leaveClass: '',
      leaveActiveClass: '',
      leaveToClass: ''
    },
    classes: {
      wrapper: '',
      buttonWrapper: '',
      selectButton: 'bg-white border-gray-300',
      selectButtonLabel: '',
      selectButtonPlaceholder: 'text-gray-400',
      selectButtonIcon: 'text-gray-600',
      selectButtonClearButton: 'hover:bg-indigo-100 text-gray-600',
      selectButtonClearIcon: '',
      dropdown: 'bg-white border-gray-300',
      dropdownFeedback: 'pb-2 px-3 text-gray-400 text-sm',
      loadingMoreResults: 'pb-2 px-3 text-gray-400 text-sm',
      optionsList: '',
      searchWrapper: 'p-2 placeholder-gray-400',
      searchBox: 'd-input d-input-bordered d-input-sm',
      optgroup: 'text-gray-400 uppercase text-xs py-1 px-2 font-semibold',
      option: '',
      disabledOption: '',
      highlightedOption: 'bg-indigo-200',
      selectedOption: 'font-semibold bg-gray-100 bg-primary font-semibold text-white',
      selectedHighlightedOption: 'font-semibold bg-gray-100 bg-primary font-semibold text-white',
      optionContent: 'flex justify-between items-center px-3 py-2',
      optionLabel: '',
      selectedIcon: '',
      enterClass: 'opacity-0',
      enterActiveClass: 'transition ease-out duration-100',
      enterToClass: 'opacity-100',
      leaveClass: 'opacity-100',
      leaveActiveClass: 'transition ease-in duration-75',
      leaveToClass: 'opacity-0'
    },
    variants: {
      danger: {
        selectButton: 'border-red-300 bg-red-50 text-red-900',
        selectButtonPlaceholder: 'text-red-200',
        selectButtonIcon: 'text-red-500',
        selectButtonClearButton: 'hover:bg-red-200 text-red-500',
        dropdown: 'bg-red-50 border-red-300'
      },
    }
  }
}
