<template>
	<div
		class="grid grid-cols-1 md:grid-cols-12 rounded-xl overflow-auto md:overflow-hidden"
		:class="[wrapperClass]"
		@click="$emit('click', $event)"
	>
		<div class="md:overflow-y-auto" :class="[leftColSpanBase12, leftClass]">
			<slot name="left"></slot>
		</div>
		<div class="md:overflow-y-auto" :class="[rightColSpanBase12, rightClass]">
			<slot name="right"></slot>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue'

export default {
	props: {
		wrapperClass: {
			type: String,
			default: 'bg-white',
		},
		leftClass: {
			type: String,
			default: 'bg-transparent',
		},
		rightClass: {
			type: String,
			default: 'bg-transparent',
		},
		leftColSpanBase12: {
			type: String,
			default: 'col-span-6',
		},
		rightColSpanBase12: {
			type: String,
			default: 'col-span-6',
		},
	},

	setup(props, ctx) {
		return {}
	},
}
</script>
