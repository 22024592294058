<template>
	<div
		v-drag="'.drag-me-' + uniqId"
		@mousedown="
			() => {
				zIndex = getNextZIndex()
			}
		"
		class="rounded-xl shadow-xl z-50 max-h-4xl overflow-y-auto"
		:class="[containerClass, positionClass]"
		:style="zIndexStyle"
	>
		<div
			class="sticky top-0 left-0 z-10 bg-slate-200 hover:bg-slate-300 mb-2 p-4 flex justify-between items-center drag-me"
			:class="'drag-me-' + uniqId"
		>
			<p class="font-bold">{{ title }}</p>
			<button class="d-btn d-btn-ghost d-btn-circle d-btn-xs" @click="emit('hide')">
				<icon icon="close"></icon>
			</button>
		</div>
		<div :class="contentClass">
			<slot> </slot>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useDraggableStore } from './draggable-store'

const props = defineProps({
	title: {
		type: String,
		default: '',
	},
	positionClass: {
		type: String,
		default: 'top-20 left-20',
	},
	containerClass: {
		type: String,
		default: 'min-w-3xl max-w-3xl bg-slate-200',
	},
	contentClass: {
		type: String,
		default: 'px-6 pb-6',
	},
})

const emit = defineEmits(['hide'])

const zIndex = ref()
const zIndexStyle = computed(() => {
	return 'z-index: ' + zIndex.value + ';'
})

const draggableStore = useDraggableStore()
const { getNextZIndex } = draggableStore

const uniqId = computed(() => {
	return _.uniqueId('drag')
})

onMounted(() => {
	zIndex.value = getNextZIndex()
})
</script>

<style lang="scss" scoped>
.drag-draggable {
	@apply fixed;
}
</style>
