import {
  TTable,
} from 'vue-tailwind/dist/components'

export default {
  component: TTable,
  props: {
    classes: {
      table: 'min-w-full divide-y divide-gray-100 border-gray-200 border',
      thead: '',
      theadTr: '',
      theadTh: 'px-3 py-2 font-semibold text-left bg-gray-100 border-b',
      tbody: 'bg-white divide-y divide-gray-100',
      tr: '',
      td: 'px-3 py-2 whitespace-no-wrap',
      tfoot: '',
      tfootTr: '',
      tfootTd: ''
    },
    variants: {
      thin: {
        td: 'p-1 whitespace-no-wrap text-sm',
        theadTh: 'p-1 font-semibold text-left bg-gray-100 border-b text-sm'
      },
      simple: {
        table: 'min-w-full divide-y divide-gray-100',
        theadTh: 'px-0 py-2 font-semibold text-left bg-transparent border-gray-200 border-b',
        tbody: 'bg-transparent divide-y divide-gray-100',
        td: 'px-0 py-2 whitespace-no-wrap',
      },
      borderless: {
        table: 'min-w-full border-0',
        theadTh: 'px-0 py-4 font-semibold text-left bg-gray-100 border-0',
        tbody: 'bg-white',
        td: 'px-0 py-2 whitespace-no-wrap',
      },
    }
  }
}

