import Vue from 'vue'
import VueTailwind from 'vue-tailwind'
import { } from 'vue-tailwind/dist/components'

import tCheckboxGroup from './tCheckboxGroup'
import tCheckboxButtons from './tCheckboxButtons'

import tRadioGroup from './tRadioGroup'
import tRadioButtons from './tRadioButtons'
import tRadioCardsStacked from './tRadioCardsStacked'

import tLink from './tLink'

import tRichSelect from './tRichSelect'
import tTable from './tTable'
import tDialog from './tDialog'
import tModal from './tModal'
import tModalDetail from './tModalDetail'

const settings = {
  't-link': tLink,

  't-checkbox-group': tCheckboxGroup,
  't-checkbox-buttons': tCheckboxButtons,

  't-radio-group': tRadioGroup,
  't-radio-buttons': tRadioButtons,
  't-radio-cards-stacked': tRadioCardsStacked,
  
  't-rich-select': tRichSelect,
  't-table': tTable,
 
  't-dialog': tDialog,
  't-modal': tModal,
  't-modal-detail': tModalDetail,
}

Vue.use(VueTailwind, settings)
