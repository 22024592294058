<template>
	<div class="relative inline-flex rounded-full" :class="[containerClass, colorClass]">
		<div class="animate-ping-slow absolute inline-flex h-full w-full rounded-full opacity-75" :class="colorClass"></div>
	</div>
</template>

<script>
export default {
	props: {
		containerClass: {
			type: String,
			default: 'h-3 w-3',
		},
		colorClass: {
			type: String,
			default: 'bg-indigo-600',
		},
	},
}
</script>
