
import {
  TCheckboxGroup,
} from 'vue-tailwind/dist/components'

export default {
  component: TCheckboxGroup,
  props: {
    fixedClasses: {
      groupWrapper: 'flex flex-wrap gap-2 select-none',
      label: '',
      labelChecked: '',
      inputWrapper: '',
      inputWrapperChecked: '',
      input: 'absolute invisible'
    },
    classes: {
      wrapper: 'd-btn d-btn-accent disabled:d-btn-disabled',
      wrapperChecked: 'd-btn d-btn-primary disabled:d-btn-disabled',
    },
    variants: {
      danger: {
        wrapper: 'd-btn d-btn d-btn-error disabled:d-btn-disabled',
        wrapperChecked: 'd-btn d-btn d-btn-error disabled:d-btn-disabled',
      },
      disabled: {
        wrapper: 'd-btn d-btn d-btn-error d-btn-disabled',
        wrapperChecked: 'd-btn d-btn d-btn-error d-btn-disabled',
      },
      sm: {
        wrapper: 'd-btn d-btn-sm d-btn-accent disabled:d-btn-disabled',
        wrapperChecked: 'd-btn d-btn-sm d-btn-primary disabled:d-btn-disabled',
      }
    }
  }
}
