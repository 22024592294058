import { Buffer } from 'buffer'

export const can = (action, subject) => {
	const persistedStore = JSON.parse(localStorage.getItem('rysqer'))
	const abilities = persistedStore && persistedStore.authUser ? persistedStore.authUser.abilities : []

	if (!abilities || !abilities.length) {
		return false
	}

	return abilities.some((ability) => ability.action === action && ability.subject == subject)
}

export const b64Encode = (str) => {
	return Buffer.from(str, 'utf-8').toString('base64')
}

export const b64Decode = (str) => {
	return Buffer.from(str, 'base64').toString()
}

export const clickToDownload = (data, filename) => {
	const blob = new Blob([data])

	if (navigator.appVersion.toString().indexOf('.NET') > 0) {
		window.navigator.msSaveBlob(blob, filename)
	} else {
		const url = window.URL.createObjectURL(blob)
		const link = document.createElement('a')

		link.href = url
		link.setAttribute('download', filename)

		document.body.appendChild(link)
		link.click()
	}
}

export const getValueForLabel = (label, options) => {
	const entry = options.find((o) => o.label === label)
	return entry ? entry.value : null
}

export const getLabelForValue = (value, options) => {
	const entry = options.find((o) => o.value === value)
	return entry ? entry.label : null
}
