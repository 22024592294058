import {
  TModal,
} from 'vue-tailwind/dist/components'

export default {
  component: TModal,
    props: {
      fixedClasses: {
        overlay: 'z-40  overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50 flex flex-col justify-center items-center',
        wrapper: 'relative z-50 min-w-lg max-w-lg px-3 py-12',
        modal: 'overflow-visible relative rounded-lg shadow-xl',
        body: 'p-3',
        header: 'border-b p-3 rounded-t-lg',
        footer: ' p-3 rounded-b-lg',
        close: 'flex items-center justify-center rounded-full absolute right-0 top-0  -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50'
      },
      classes: {
        overlay: 'bg-black',
        wrapper: '',
        modal: 'bg-white shadow',
        body: 'p-3',
        header: 'border-gray-100',
        footer: 'bg-gray-100',
        close: 'bg-gray-100 text-gray-600 hover:bg-gray-200',
        closeIcon: 'fill-current h-4 w-4',
        overlayEnterClass: 'opacity-0',
        overlayEnterActiveClass: 'transition ease-out duration-100',
        overlayEnterToClass: 'opacity-100',
        overlayLeaveClass: 'opacity-100',
        overlayLeaveActiveClass: 'transition ease-in duration-75',
        overlayLeaveToClass: 'opacity-0',
        enterClass: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95',
        enterActiveClass: 'ease-out duration-300',
        enterToClass: 'opacity-100 translate-y-0 sm:scale-100',
        leaveClass: 'opacity-100 translate-y-0 sm:scale-100',
        leaveActiveClass: 'ease-in duration-200',
        leaveToClass: 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
      },
      variants: {
        danger: {
          overlay: 'bg-red-100',
          header: 'border-red-50 text-red-700',
          close: 'bg-red-50 text-red-700 hover:bg-red-200 border-red-100 border',
          modal: 'bg-white border border-red-100 shadow-lg',
          footer: 'bg-red-50'
        },
        rose: {
          overlay: 'bg-black',
          header: 'border-rose-50 text-rose-700',
          close: 'bg-rose-50 text-rose-700 hover:bg-rose-200 border-rose-100 border',
          modal: 'bg-rose-100 border border-rose-100 shadow-lg',
          footer: 'bg-rose-50'
        },
        emerald: {
          overlay: 'bg-black',
          header: 'border-emerald-50 text-emerald-700',
          close: 'bg-emerald-50 text-emerald-700 hover:bg-emerald-200 border-emerald-100 border',
          modal: 'bg-emerald-100 border border-emerald-100 shadow-lg',
          footer: 'bg-emerald-50'
        }
      }
    }
}

