<template>
	<div>
		<div v-if="!loading">
			<Vueform
				ref="refForm"
				v-bind="definition"
				v-model="form"
				sync
				:disabled="disabled"
				@submit="onSubmit"
				:endpoint="false"
			>
			</Vueform>
		</div>
		<div class="flex justify-center" v-else>
			<span class="text-center d-loading text-primary"></span>
		</div>
	</div>
</template>

<script setup>
import { ref, watch, computed, watchEffect, onMounted, onUnmounted } from 'vue'
import axios from '@/libs/axios'
import jsonLogic from 'json-logic-js'
import transformJS from 'js-to-json-logic' //https://github.com/krismuniz/js-to-json-logic

const props = defineProps({
	definitionEndpoint: {
		type: String,
	},
	valuesEndpoint: {
		type: String,
	},
	createOnly: {
		type: Boolean,
		default: false,
	},
	submitOnStep: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
})

const emit = defineEmits(['submit', 'success', 'beforeCreate', 'mounted'])

const definition = ref()
const callbacks = ref()
const refForm = ref(null)
const form = ref({})
const busy = ref()
const loading = ref()

watch(
	() => props.definitionEndpoint,
	() => {
		console.log('Entering form watcher')
		loading.value = true
		Promise.all([
			axios.get(props.definitionEndpoint).then(({ data }) => {
				console.log('Loading definition finished.')
				definition.value = data.definition
				callbacks.value = data.callbacks
				console.log('Assigning definition finished.')
			}),

			!props.createOnly
				? axios.get(props.valuesEndpoint).then(({ data }) => {
						console.log('Loading values finished.')
						form.value = data
				  })
				: null,
		])
			.catch((error) => console.log(error))
			.finally(() => {
				console.log('Finally finishing preparing form.')
				loading.value = false

				/* definition.value.schema.measures.conditions = [
					(form$, el$) => {
						let status = form$.el$('status')?.value
						if (!status) status = 'open'

						let a = ['open', 'all'].includes(status)
						let b = el$.value.some((e) => e.state == 'open')
						return a && b
					},
				] */
			})
	},
	{ immediate: true }
)

const onNextPage = () => {
	const next = refForm.value.steps$.next$ ? refForm.value.steps$.next$.label : refForm.value.steps$.last$.label
	console.log('Running callbacks for page: ' + next)

	let pageCallbacks = callbacks.value.find((element) => element.toPage === next)

	if (pageCallbacks && pageCallbacks.callbacks && pageCallbacks.callbacks.length > 0) {
		for (let i = 0; i < pageCallbacks.callbacks.length; i++) {
			let callback = pageCallbacks.callbacks[i]
			if (!callback.rule || (callback.rule && jsonLogic.apply(callback.rule, form.value))) {
				if (callback.callback in callbackMethods) {
					callbackMethods[callback.callback](...callback.params)
					if (callback.stopIfTrue) {
						console.log('Stopping processing callbacks because of flag')
						break
					}
				}
			}
		}
	}

	if (props.submitOnStep) {
		const current = refForm.value.steps$.current$.label
		axios.post(props.valuesEndpoint, { step: current, ...form.value })
	}
}

watchEffect(() => {
	console.log('Running watch effect...')
	if (refForm.value && refForm.value.steps$) {
		refForm.value.steps$.on('next', () => {
			onNextPage()
		})
	}
})

const onSubmit = () => {
	axios
		.post(props.valuesEndpoint, {
			step: refForm.value.steps$ ? refForm.value.steps$.last$.label : null,
			...form.value,
		})
		.then(() => {
			emit('success', form.value)
		})
		.catch((error) => {
			emit('eror', error)
		})

	emit('submit', form.value)
}

const reset = () => {
	refForm.value.reset()
}

const clear = () => {
	refForm.value.clear()
}

// ------------------------------------------------
// Callback definitions
// ------------------------------------------------
const removeElementItem = (element, value) => {
	console.log('Remove ' + value + ' on ' + element)
	let items = definition.value.schema[element].items
	let finalItems = _.filter(items, (o) => o.value !== value)

	definition.value.schema[element].items = finalItems
}

const removeElementItems = (element, items) => {
	items.forEach((item) => {
		removeElementItem(element, item)
	})
}

const alignElementItems = (fromScale, toScale) => {
	console.log('Align element item values' + fromScale + ' -> ' + toScale)
	let schemaOptions = definition.value.schema[fromScale].items
	let formValue = _.get(form.value, fromScale)
	let filteredSchemaOptions = _.filter(schemaOptions, (o) => o.value <= formValue)

	definition.value.schema[toScale].items = filteredSchemaOptions
}

const maxElementItems = (fromArr, toArr) => {
	console.log('Max  element items')
	let schema = definition.value.schema

	for (let i = 0; i < fromArr.length; i++) {
		let fromItems = schema[fromArr[i]].items
		let formValue = _.get(form.value, fromArr[i])
		let filtered = fromItems.filter((f) => f.value <= formValue)
		schema[toArr[i]].items = filtered
	}

	definition.value.schema = schema
}

const disableElement = (element) => {
	// attribute disabled: false must be set on element, otherwise it is not working
	console.log('Disable element: ' + element)
	definition.value.schema[element].disabled = true
}

const disableElements = (elements) => {
	// attribute disabled: false must be set on element, otherwise it is not working
	elements.forEach((e) => {
		disableElement(e)
	})
}

const setFormValue = (item, value) => {
	console.log('Set form value ' + item + ' to ' + value)
	_.set(form.value, item, value)
}

const resetFormValue = (item) => {
	console.log('Reset form value: ' + item)
	_.set(form.value, item, null)
}

const resetFormValues = (items) => {
	items.forEach((item) => {
		resetFormValue(item)
	})
}

const transferFormValue = (fromItem, toItem) => {
	console.log('Transer form value from' + fromItem + ' to ' + toItem)
	_.set(form.value, toItem, _.get(form.value, fromItem))
}

const transferFormValues = (fromToItemsArray) => {
	fromToItemsArray.forEach((ftItem) => {
		transferFormValue(ftItem[0], ftItem[1])
	})
}

const callbackMethods = {
	removeElementItem,
	removeElementItems,
	alignElementItems,
	maxElementItems,
	disableElement,
	disableElements,
	setFormValue,
	resetFormValue,
	resetFormValues,
	transferFormValue,
	transferFormValues,
}

defineExpose({ form, refForm, definition, transformJS, callbackMethods, reset, clear })
</script>

<style lang="scss">
.vf-edit-button input[type='checkbox'] {
	display: block;
}

.vf-view-disabled {
	.form-bg-disabled {
		@apply bg-transparent mx-0 text-gray-800;
	}
	.form-pl-input-lg {
		@apply pl-0;
	}
	.form-border-color-input {
		@apply border-none;
	}
}

.vf-readonly-textarea,
.vf-readonly-textinput {
	@apply cursor-pointer;
	.form-p-input-lg,
	.form-p-input-sm {
		@apply p-0 bg-slate-100;
	}
	.form-radius-large-lg,
	.form-radius-large-sm {
		@apply border-none outline-none;
	}
	.form-radius-input-lg,
	.form-radius-input-sm {
		@apply border-none outline-none;
	}
}

*,
*:before,
*:after,
:root {
	--vf-primary: #570cf8;
	--vf-primary-darker: #4700e0;
	--vf-color-on-primary: #ffffff;
	--vf-danger: #ef4444;
	--vf-danger-lighter: #fee2e2;
	--vf-success: #10b981;
	--vf-success-lighter: #d1fae5;
	--vf-ring-width: 2px;
	--vf-ring-color: #07bf9b66;
	--vf-gray-50: #f9fafb;
	--vf-gray-100: #f3f4f6;
	--vf-gray-200: #e5e7eb;
	--vf-gray-300: #d1d5db;
	--vf-gray-400: #9ca3af;
	--vf-gray-500: #6b7280;
	--vf-gray-600: #4b5563;
	--vf-gray-700: #374151;
	--vf-gray-800: #1f2937;
	--vf-gray-900: #111827;
	--vf-font-size: 1rem;
	--vf-font-size-sm: 0.875rem;
	--vf-font-size-lg: 1rem;
	--vf-font-size-small: 0.875rem;
	--vf-font-size-small-sm: 0.8125rem;
	--vf-font-size-small-lg: 0.875rem;
	--vf-line-height: 1.5rem;
	--vf-line-height-sm: 1.25rem;
	--vf-line-height-lg: 1.5rem;
	--vf-line-height-small: 1.25rem;
	--vf-line-height-small-sm: 1.125rem;
	--vf-line-height-small-lg: 1.25rem;
	--vf-letter-spacing: 0px;
	--vf-letter-spacing-sm: 0px;
	--vf-letter-spacing-lg: 0px;
	--vf-letter-spacing-small: 0px;
	--vf-letter-spacing-small-sm: 0px;
	--vf-letter-spacing-small-lg: 0px;
	--vf-gutter: 1rem;
	--vf-gutter-sm: 0.5rem;
	--vf-gutter-lg: 1rem;
	--vf-min-height-input: 2.375rem;
	--vf-min-height-input-sm: 2.125rem;
	--vf-min-height-input-lg: 2.875rem;
	--vf-py-input: 0.375rem;
	--vf-py-input-sm: 0.375rem;
	--vf-py-input-lg: 0.625rem;
	--vf-px-input: 0.75rem;
	--vf-px-input-sm: 0.5rem;
	--vf-px-input-lg: 0.875rem;
	--vf-py-btn: 0.375rem;
	--vf-py-btn-sm: 0.375rem;
	--vf-py-btn-lg: 0.625rem;
	--vf-px-btn: 0.875rem;
	--vf-px-btn-sm: 0.75rem;
	--vf-px-btn-lg: 1.25rem;
	--vf-py-btn-small: 0.25rem;
	--vf-py-btn-small-sm: 0.25rem;
	--vf-py-btn-small-lg: 0.375rem;
	--vf-px-btn-small: 0.625rem;
	--vf-px-btn-small-sm: 0.625rem;
	--vf-px-btn-small-lg: 0.75rem;
	--vf-py-group-tabs: 0.375rem;
	--vf-py-group-tabs-sm: 0.375rem;
	--vf-py-group-tabs-lg: 0.625rem;
	--vf-px-group-tabs: 0.75rem;
	--vf-px-group-tabs-sm: 0.5rem;
	--vf-px-group-tabs-lg: 0.875rem;
	--vf-py-group-blocks: 0.75rem;
	--vf-py-group-blocks-sm: 0.625rem;
	--vf-py-group-blocks-lg: 0.875rem;
	--vf-px-group-blocks: 1rem;
	--vf-px-group-blocks-sm: 1rem;
	--vf-px-group-blocks-lg: 1rem;
	--vf-py-tag: 0px;
	--vf-py-tag-sm: 0px;
	--vf-py-tag-lg: 0px;
	--vf-px-tag: 0.4375rem;
	--vf-px-tag-sm: 0.4375rem;
	--vf-px-tag-lg: 0.4375rem;
	--vf-py-slider-tooltip: 0.125rem;
	--vf-py-slider-tooltip-sm: 0.0625rem;
	--vf-py-slider-tooltip-lg: 0.1875rem;
	--vf-px-slider-tooltip: 0.375rem;
	--vf-px-slider-tooltip-sm: 0.3125rem;
	--vf-px-slider-tooltip-lg: 0.5rem;
	--vf-space-addon: 0px;
	--vf-space-addon-sm: 0px;
	--vf-space-addon-lg: 0px;
	--vf-space-checkbox: 0.375rem;
	--vf-space-checkbox-sm: 0.375rem;
	--vf-space-checkbox-lg: 0.375rem;
	--vf-space-tags: 0.1875rem;
	--vf-space-tags-sm: 0.1875rem;
	--vf-space-tags-lg: 0.1875rem;
	--vf-floating-top: 0rem;
	--vf-floating-top-sm: 0rem;
	--vf-floating-top-lg: 0.6875rem;
	--vf-bg-input: #ffffff;
	--vf-bg-input-hover: #ffffff;
	--vf-bg-input-focus: #ffffff;
	--vf-bg-input-danger: #ffffff;
	--vf-bg-input-success: #ffffff;
	--vf-bg-disabled: var(--vf-gray-200);
	--vf-bg-selected: #1118270d;
	--vf-bg-passive: var(--vf-gray-300);
	--vf-bg-icon: var(--vf-gray-500);
	--vf-bg-danger: var(--vf-danger-lighter);
	--vf-bg-success: var(--vf-success-lighter);
	--vf-bg-tag: var(--vf-primary);
	--vf-bg-slider-handle: var(--vf-primary);
	--vf-bg-toggle-handle: #ffffff;
	--vf-bg-date-head: var(--vf-gray-100);
	--vf-bg-addon: #ffffff00;
	--vf-bg-btn: var(--vf-primary);
	--vf-bg-btn-danger: var(--vf-danger);
	--vf-bg-btn-secondary: var(--vf-gray-200);
	--vf-color-input: var(--vf-gray-800);
	--vf-color-input-hover: var(--vf-gray-800);
	--vf-color-input-focus: var(--vf-gray-800);
	--vf-color-input-danger: var(--vf-gray-800);
	--vf-color-input-success: var(--vf-gray-800);
	--vf-color-disabled: var(--vf-gray-400);
	--vf-color-placeholder: var(--vf-gray-300);
	--vf-color-passive: var(--vf-gray-700);
	--vf-color-muted: var(--vf-gray-500);
	--vf-color-floating: var(--vf-gray-500);
	--vf-color-floating-focus: var(--vf-gray-500);
	--vf-color-floating-success: var(--vf-gray-500);
	--vf-color-floating-danger: var(--vf-gray-500);
	--vf-color-danger: var(--vf-danger);
	--vf-color-success: var(--vf-success);
	--vf-color-tag: var(--vf-color-on-primary);
	--vf-color-addon: var(--vf-gray-800);
	--vf-color-date-head: var(--vf-gray-700);
	--vf-color-btn: var(--vf-color-on-primary);
	--vf-color-btn-danger: #ffffff;
	--vf-color-btn-secondary: var(--vf-gray-700);
	--vf-border-color-input: var(--vf-gray-300);
	--vf-border-color-input-hover: var(--vf-gray-300);
	--vf-border-color-input-focus: var(--vf-primary);
	--vf-border-color-input-danger: var(--vf-gray-300);
	--vf-border-color-input-success: var(--vf-gray-300);
	--vf-border-color-checked: var(--vf-primary);
	--vf-border-color-passive: var(--vf-gray-300);
	--vf-border-color-slider-tooltip: var(--vf-primary);
	--vf-border-color-tag: var(--vf-primary);
	--vf-border-color-btn: var(--vf-primary);
	--vf-border-color-btn-danger: var(--vf-danger);
	--vf-border-color-btn-secondary: var(--vf-gray-200);
	--vf-border-width-input-t: 1px;
	--vf-border-width-input-r: 1px;
	--vf-border-width-input-b: 1px;
	--vf-border-width-input-l: 1px;
	--vf-border-width-radio-t: 1px;
	--vf-border-width-radio-r: 1px;
	--vf-border-width-radio-b: 1px;
	--vf-border-width-radio-l: 1px;
	--vf-border-width-checkbox-t: 1px;
	--vf-border-width-checkbox-r: 1px;
	--vf-border-width-checkbox-b: 1px;
	--vf-border-width-checkbox-l: 1px;
	--vf-border-width-dropdown: 1px;
	--vf-border-width-btn: 1px;
	--vf-border-width-toggle: 0.125rem;
	--vf-border-width-tag: 1px;
	--vf-shadow-input: 0px 0px 0px 0px rgba(0, 0, 0, 0);
	--vf-shadow-input-hover: 0px 0px 0px 0px rgba(0, 0, 0, 0);
	--vf-shadow-input-focus: 0px 0px 0px 0px rgba(0, 0, 0, 0);
	--vf-shadow-handles: 0px 0px 0px 0px rgba(0, 0, 0, 0);
	--vf-shadow-handles-hover: 0px 0px 0px 0px rgba(0, 0, 0, 0);
	--vf-shadow-handles-focus: 0px 0px 0px 0px rgba(0, 0, 0, 0);
	--vf-shadow-btn: 0px 0px 0px 0px rgba(0, 0, 0, 0);
	--vf-shadow-dropdown: 0px 0px 0px 0px rgba(0, 0, 0, 0);
	--vf-radius-input: 6px;
	--vf-radius-input-sm: 6px;
	--vf-radius-input-lg: 6px;
	--vf-radius-btn: 0.25rem;
	--vf-radius-btn-sm: 0.5rem;
	--vf-radius-btn-lg: 0.5rem;
	--vf-radius-small: 0.25rem;
	--vf-radius-small-sm: 0.25rem;
	--vf-radius-small-lg: 0.25rem;
	--vf-radius-large: 0.25rem;
	--vf-radius-large-sm: 0.25rem;
	--vf-radius-large-lg: 0.25rem;
	--vf-radius-tag: 0.25rem;
	--vf-radius-tag-sm: 0.25rem;
	--vf-radius-tag-lg: 0.25rem;
	--vf-radius-checkbox: 0.25rem;
	--vf-radius-checkbox-sm: 0.25rem;
	--vf-radius-checkbox-lg: 0.25rem;
	--vf-radius-slider: 0.25rem;
	--vf-radius-slider-sm: 0.25rem;
	--vf-radius-slider-lg: 0.25rem;
	--vf-radius-image: 0.25rem;
	--vf-radius-image-sm: 0.25rem;
	--vf-radius-image-lg: 0.25rem;
	--vf-radius-gallery: 0.25rem;
	--vf-radius-gallery-sm: 0.25rem;
	--vf-radius-gallery-lg: 0.25rem;
	--vf-checkbox-size: 1rem;
	--vf-checkbox-size-sm: 0.875rem;
	--vf-checkbox-size-lg: 1rem;
	--vf-gallery-size: 6rem;
	--vf-gallery-size-sm: 5rem;
	--vf-gallery-size-lg: 7rem;
	--vf-toggle-width: 3rem;
	--vf-toggle-width-sm: 2.75rem;
	--vf-toggle-width-lg: 3rem;
	--vf-toggle-height: 1.25rem;
	--vf-toggle-height-sm: 1rem;
	--vf-toggle-height-lg: 1.25rem;
	--vf-slider-height: 0.375rem;
	--vf-slider-height-sm: 0.3125rem;
	--vf-slider-height-lg: 0.5rem;
	--vf-slider-height-vertical: 20rem;
	--vf-slider-height-vertical-sm: 20rem;
	--vf-slider-height-vertical-lg: 20rem;
	--vf-slider-handle-size: 1rem;
	--vf-slider-handle-size-sm: 0.875rem;
	--vf-slider-handle-size-lg: 1.25rem;
	--vf-slider-tooltip-distance: 0.5rem;
	--vf-slider-tooltip-distance-sm: 0.375rem;
	--vf-slider-tooltip-distance-lg: 0.5rem;
	--vf-slider-tooltip-arrow-size: 0.3125rem;
	--vf-slider-tooltip-arrow-size-sm: 0.3125rem;
	--vf-slider-tooltip-arrow-size-lg: 0.3125rem;
}
</style>
