<template>
	<div>
		<div class="flex justify-end items-center gap-2 pb-4">
			<div class="flex gap-2" v-if="!disabled">
				<button
					class="d-btn d-btn-accent d-btn-sm gap-2"
					@click="editing = true"
					v-if="!disabled && !adding && !editing && localAttached && localAttached.length > 0"
				>
					<icon icon="pencil"></icon>
					<span>Edit</span>
				</button>
				<button
					class="d-btn d-btn-accent d-btn-sm gap-2"
					@click="adding = true"
					v-if="!disabled && !adding && !editing"
				>
					<icon icon="plus"></icon>
					<span>Add</span>
				</button>
				<button class="d-btn d-btn-accent d-btn-sm" @click="editing = false" v-if="editing">Close</button>
				<button class="d-btn d-btn-accent d-btn-sm" @click="adding = false" v-if="adding">Cancel</button>
			</div>
		</div>

		<!-- ------------------------------------------------- -->
		<!-- ATTACHED  -->
		<!-- ------------------------------------------------- -->
		<div v-if="!adding">
			<rysqer-tiles :data="localAttached" container-class="block">
				<template #no-result> <slot name="empty-attached"></slot></template>

				<template v-slot:data="{ data: attached, index }">
					<div class="grid grid-cols-12 items-center py-2">
						<!-- ------------------------------------------------- -->
						<!-- Left  -->
						<!-- ------------------------------------------------- -->
						<div :class="[editing ? 'col-span-10 md:col-span-11' : 'col-span-12']">
							<slot name="attached" :attached="attached"></slot>
						</div>

						<!-- ------------------------------------------------- -->
						<!-- Right  -->
						<!-- ------------------------------------------------- -->
						<div class="flex justify-center items-center" :class="[editing ? 'col-span-2 md:col-span-1' : null]">
							<!-- ------------------------------------------------- -->
							<!-- Add / Remove  -->
							<!-- ------------------------------------------------- -->
							<div v-if="!disabled">
								<button class="d-btn d-btn-error d-btn-square d-btn-sm" @click="detach(attached, index)" v-if="editing">
									<icon icon="minus" cls="text-white w-4 h-4"></icon>
								</button>
							</div>
						</div>
					</div>
				</template>
			</rysqer-tiles>
		</div>

		<!-- ------------------------------------------------- -->
		<!-- DETACHED  -->
		<!-- ------------------------------------------------- -->
		<div v-else class="space-y-2">
			<!-- ------------------------------------------------- -->
			<!-- SEARCH CLOUD -->
			<!-- ------------------------------------------------- -->
			<div class="flex justify-center">
				<div class="flex flex-wrap justify-center items-center gap-2">
					<button
						class="d-btn d-btn-xs"
						v-for="(searchCloudListItem, index) in searchCloudList"
						:class="[selectedSearchCloudListItem == searchCloudListItem ? 'd-btn-primary' : 'd-btn-accent']"
						:key="index"
						@click="selectedSearchCloudListItem = searchCloudListItem"
					>
						{{ searchCloudListItem }}
					</button>

					<button class="d-btn d-btn-accent d-btn-xs" @click="selectedSearchCloudListItem = null">
						<icon icon="close" cls="h-3 w-3 text-black"></icon>
					</button>
				</div>
			</div>

			<!-- ------------------------------------------------- -->
			<!-- SEARCH -->
			<!-- ------------------------------------------------- -->
			<div class="d-form-control">
				<label class="d-input-group">
					<button class="d-btn d-btn-accent btn-square" name="searchTerm" disabled="disabled">
						<icon icon="search"></icon>
					</button>
					<input type="text" class="d-input d-input-bordered w-full" v-model="searchBy.value" placeholder="Search" />
					<button
						class="d-btn d-btn-accent btn-square"
						name="resetSearchTerm"
						@click="searchBy.value = null"
						v-if="searchBy.value"
					>
						<icon icon="close"></icon>
					</button>
				</label>
			</div>

			<!-- ------------------------------------------------- -->
			<!-- LIST -->
			<!-- ------------------------------------------------- -->
			<rysqer-tiles
				:data="localDetached"
				:filter-by="
					selectedSearchCloudListItem ? { property: props.searchCloud, value: selectedSearchCloudListItem } : {}
				"
				:searchBy="searchBy"
				container-class="block"
			>
				<template #no-result> <slot name="empty-detached"></slot></template>
				<template v-slot:data="{ data: detached, index }">
					<div class="grid grid-cols-12 items-center py-2">
						<!-- ------------------------------------------------- -->
						<!-- Left  -->
						<!-- ------------------------------------------------- -->
						<div class="content-center" :class="[adding ? 'col-span-10 md:col-span-11' : 'col-span-12']">
							<slot name="detached" :detached="detached"></slot>
						</div>

						<!-- ------------------------------------------------- -->
						<!-- Right  -->
						<!-- ------------------------------------------------- -->
						<div class="flex justify-center items-center" :class="[adding ? 'col-span-2 md:col-span-1' : null]">
							<!-- ------------------------------------------------- -->
							<!-- Add / Remove  -->
							<!-- ------------------------------------------------- -->
							<div v-if="!disabled">
								<button
									class="d-btn d-btn-success d-btn-square d-btn-sm"
									@click="attach(detached, index)"
									v-if="adding"
								>
									<icon icon="plus" cls="text-white w-4 h-4"></icon>
								</button>
							</div>
						</div>
					</div>
				</template>
			</rysqer-tiles>
		</div>
	</div>
</template>

<script setup>
import { ref, computed, watch, toRefs, getCurrentInstance } from 'vue'

const props = defineProps({
	options: {
		type: [Object, Array],
	},
	value: {
		type: [Object, Array],
	},
	attribute: {
		type: String,
		default: 'uuid',
	},
	min: {
		type: Number,
		default: 0,
	},
	max: {
		type: Number,
		default: 0,
	},
	searchCloud: {
		type: String,
	},
	searchProperties: {
		type: Array,
	},
	emptyHeadline: {
		type: String,
		default: 'Nothing here',
	},
	emptyContent: {
		type: String,
		default: 'Add items to this list',
	},
	disabled: {
		type: Boolean,
		default: false,
	},
})
const vm = getCurrentInstance()
const emit = defineEmits(['input', 'item-attached', 'item-detached'])

const localOptions = ref([])
const localAttached = ref([])

const localDetached = computed(() => {
	if (!_.isEmpty(localOptions)) {
		return _.reject(localOptions.value, (o) => {
			return _.some(localAttached.value, (la) => _.get(la, props.attribute) === _.get(o, props.attribute))
		})
	}
})

const editing = ref(false)
const adding = ref(false)

const attach = (item, index) => {
	if (props.max && localAttached.value.length == props.max) {
		alertMaxLimit()
		return
	}

	localDetached.value.splice(index, 1)
	localAttached.value.push(item)
	adding.value = false

	emit('input', localAttached.value)
	emit('item-attached', item)
}

const detach = (item, index) => {
	if (props.min && localAttached.value.length == props.min) {
		alertMinLimit()
		return
	}

	localAttached.value.splice(index, 1)
	localDetached.value.push(item)
	editing.value = false

	emit('input', localAttached.value)
	emit('item-detached', item)
}

const alertMinLimit = () => {
	vm.proxy.$dialog.alert({
		title: 'Cannot remove',
		text: `At least  ${props.min} entries must be present`,
		icon: 'warning',
	})
}

const alertMaxLimit = () => {
	vm.proxy.$dialog.alert({
		title: 'Cannot add',
		text: `At least  ${props.max} entries can be present`,
		icon: 'warning',
	})
}

const searchBy = ref({
	properties: props.searchProperties,
	value: null,
})

const selectedSearchCloudListItem = ref()
const searchCloudList = computed(() => {
	if (!props.searchCloud) return []

	return _.map(_.uniqBy(localDetached.value, props.searchCloud), (i) => {
		return _.get(i, props.searchCloud)
	})
})

watch(
	() => props.options.length,
	() => {
		if (!_.isEmpty(props.options)) {
			localOptions.value = props.options
		}
	},
	{ immediate: true }
)

watch(
	() => props.value,
	() => {
		if (!_.isEmpty(props.value)) {
			localAttached.value = props.value
		}
	},
	{ immediate: true }
)
</script>
